// TODO: update with formatted message mapper
import { IntlShape } from "react-intl";

const getCategoryTitle = (key: string, intl: IntlShape) => {
  return intl.formatMessage({
    id: `category.${key}.label`,
    defaultMessage: `${key}`,
    description: `Label of ${key} group`,
  });
};

export { getCategoryTitle };
