import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { LanguageContext, Logo } from "@eriksdigital/atomic-ui/components";
import Hamburger from "../Hamburger";
import {
  HeaderInner,
  HeaderInnerWrapper,
  HeaderWrapper,
  LanguagePanel,
  LogoWrapper,
  MenuPanelContainer,
  StyledSelect
} from "./style";
import {
  languageToCode,
  LocalesDropdownItem,
  pimLocalesDropdown
} from "../../utils/languageUtils";
import ShoppingCart from "../ShoppingCart";
import LogInStatus from "../LogInStatus";
import useBasenamePath from "../../utils/useBasenamePath";
import { useRaffaella } from "@eriksdigital/raffaella";

export type SalesOrganization = {
  sales_organization: string;
  shop_url: string;
  country: string;
  store_id: number;
  guided_selling_base_url: string;
};

const Header = () => {
  const { language, setLanguage, erpSystem } = useContext<any>(LanguageContext);
  const [salesOrganization, makeResourceCallSalesOrganisation] = useRaffaella(
    "salesOrganization"
  );
  const [availableLanguages, makeResourceCallAvaliableLanguages] = useRaffaella(
    "availableLanguages"
  );
  const [user, makeResourceCallUser] = useRaffaella("user");
  const [isMenuOpen, toggleMenu] = useState<boolean>(false);
  const menu = useRef<HTMLDivElement>(null);
  const [languageDropdown, updateLanguageDropdown] = useState<
    LocalesDropdownItem[]
  >(Object.values(pimLocalesDropdown));

  const basenamePath = useBasenamePath();

  useEffect(() => {
    makeResourceCallUser &&
      makeResourceCallUser({
        apiKey: "user",
        reqData: {
          languageId: languageToCode(language),
          erpSystem: erpSystem.toUpperCase(),
          targetSystem: "SEL_PLA",
          ...(erpSystem === "ch" && {
            reconfigure: true
          })
        }
      });
  }, [makeResourceCallUser, language, erpSystem]);

  const fetchSalesOrganisation = useCallback(() => {
    makeResourceCallSalesOrganisation &&
      makeResourceCallSalesOrganisation({
        apiKey: "salesOrganization",
        ...(erpSystem === "ch" && {
          reqData: { reconfigure: true }
        })
      });
  }, [makeResourceCallSalesOrganisation, erpSystem]);

  useEffect(() => {
    fetchSalesOrganisation();
  }, [fetchSalesOrganisation]);

  const getAvailableLanguages = useCallback(() => {
    const currentStore: SalesOrganization = salesOrganization.data.find(
      (org: SalesOrganization) => org.country === erpSystem.toUpperCase()
    );
    makeResourceCallAvaliableLanguages &&
      makeResourceCallAvaliableLanguages({
        apiKey: "availableLanguages",
        reqData: {
          storeUrl: currentStore.shop_url.slice(0, -1),
          storeId: currentStore.store_id,
          ...(erpSystem === "ch" && {
            reconfigure: true
          })
        }
      });
  }, [makeResourceCallAvaliableLanguages, salesOrganization.data, erpSystem]);

  useEffect(() => {
    if (salesOrganization.data) {
      getAvailableLanguages();
    }
  }, [salesOrganization.data, getAvailableLanguages]);

  const createDropdown = useCallback(() => {
    const newDropdown = availableLanguages.data.map(
      (lang: any) => pimLocalesDropdown[lang.countryCode]
    );
    updateLanguageDropdown(newDropdown);
    if (
      !newDropdown.find(
        (locale: LocalesDropdownItem) => locale.value === language
      )
    ) {
      setLanguage(newDropdown[0].value);
    }
  }, [availableLanguages.data, language, setLanguage]);

  useEffect(() => {
    if (availableLanguages.data && salesOrganization.data) {
      createDropdown();
    }
  }, [availableLanguages.data, salesOrganization.data, createDropdown]);

  function handleChangeLanguage({
    target: { value }
  }: {
    target: { value: string };
  }) {
    setLanguage(value);
  }

  const handleMenuToggle = () => {
    toggleMenu(!isMenuOpen);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (menu.current && menu.current.contains(e.target as HTMLElement)) {
      return;
    }

    toggleMenu(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => document.removeEventListener("click", handleClickOutside);
  }, [isMenuOpen]);

  const { loggedIn, isGuestUser } = user.data || {};

  const shouldShowCart = () => {
    if (erpSystem === "ch") {
      return !loggedIn;
    }

    return isGuestUser;
  };

  return (
    <HeaderWrapper data-testid="header">
      <HeaderInnerWrapper>
        <HeaderInner>
          <LogoWrapper to={basenamePath} rel="noopener noreferrer">
            <Logo inverted={null} width="100" height="64" />
          </LogoWrapper>
          <div style={{ display: "flex", alignItems: "center" }}>
            {!shouldShowCart() && <ShoppingCart />}
            <Hamburger
              id="menu-button"
              onClick={handleMenuToggle}
              open={isMenuOpen}
            />
            {user.data && (
              <LogInStatus user={user.data} isLoading={user.isLoading} />
            )}
          </div>
        </HeaderInner>
        <MenuPanelContainer id="menu" ref={menu}>
          <LanguagePanel data-testid="menu-panel" menuOpen={isMenuOpen}>
            <StyledSelect
              data-testid="language-select"
              onChange={handleChangeLanguage}
              value={language}
            >
              {languageDropdown.map((lang: LocalesDropdownItem) => {
                return (
                  <option value={lang.value} key={lang.value}>
                    {lang.label}
                  </option>
                );
              })}
            </StyledSelect>
          </LanguagePanel>
        </MenuPanelContainer>
      </HeaderInnerWrapper>
    </HeaderWrapper>
  );
};

export default Header;
