import React from "react";
import { Icon, Button } from "@eriksdigital/atomic-ui/components";
import { BackArrowIcon } from "@eriksdigital/atomic-ui/components/Icons";

import { ButtonBackWrapper } from "./styles";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import useBasenamePath from "../../utils/useBasenamePath";
import { useClearResource } from "@eriksdigital/raffaella";

const ButtonBack: React.FC = () => {
  const history = useHistory();
  const basenamePath = useBasenamePath();
  const clearItems = useClearResource("items");
  const onNavigationBack = () => {
    clearItems();
    history.push(`${basenamePath}/categories`);
  };

  return (
    <ButtonBackWrapper>
      <Button
        id="ghost"
        variant="ghost"
        stretch="auto"
        leftIcon={<Icon as={BackArrowIcon} />}
        onClick={onNavigationBack}
      >
        <FormattedMessage
          id="step2.startOver"
          defaultMessage="Choose product category"
          description="Label of back button"
        />
      </Button>
    </ButtonBackWrapper>
  );
};

export default ButtonBack;
