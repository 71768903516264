import {
  CON_PL_ROD_LENGTH_MM,
  CON_PL_SH_LENGTH_MM,
  CON_PL_SH_WIDTH_MM,
  CON_PL_TUBE_LENGTH_MM,
  CON_PL_GR_LENGTH_MM,
  CON_PL_GR_WIDTH_MM,
  CON_PL_PROF_LENGTH_MM
} from "../constants";

type ShapeValues = {
  [k: string]: {
    [k: string]: string | number | undefined;
  }[];
};

type ValuesType = {
  type: string;
  width?: number;
  length?: number;
};

export const getCharacteristics = ({ type, length, width }: ValuesType) => {
  const characteristics: ShapeValues = {
    rod: [
      {
        name: CON_PL_ROD_LENGTH_MM,
        value: length
      }
    ],
    sheet: [
      { name: CON_PL_SH_LENGTH_MM, value: length },
      { name: CON_PL_SH_WIDTH_MM, value: width }
    ],
    tube: [{ name: CON_PL_TUBE_LENGTH_MM, value: length }],
    grate: [
      {
        name: CON_PL_GR_LENGTH_MM,
        value: length
      },
      { name: CON_PL_GR_WIDTH_MM, value: width }
    ],
    profile: [
      {
        name: CON_PL_PROF_LENGTH_MM,
        value: length
      }
    ]
  };

  return characteristics[type];
};
