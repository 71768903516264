import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, LanguageContext } from "@eriksdigital/atomic-ui/components";
import { useRaffaella } from "@eriksdigital/raffaella";
import { useHistory } from "react-router-dom";
import { GTMContext } from "@eriksdigital/gs-ga4";
import { useIntl } from "react-intl";
import { CategoryTile } from "../CategoryTile";
import useBasenamePath from "../../utils/useBasenamePath";
import {
  categoriesMap,
  selectCategoriesForErpSystem,
  sendViewEvent
} from "../../utils/utils";
import { CategoryTitleH4 } from "./styles";
import { getCategoryTitle } from "./utils";

const CategoriesScreen = () => {
  const { erpSystem } = useContext<any>(LanguageContext);
  const intl = useIntl();
  const [user] = useRaffaella("user");

  const [categories, setCategories] = useState(
    selectCategoriesForErpSystem(erpSystem, categoriesMap)
  );

  useEffect(() => {
    setCategories(selectCategoriesForErpSystem(erpSystem, categoriesMap));
  }, [erpSystem]);

  const {
    isReadyToSendEvents,
    sendProcessStartEvent,
    sendProcessInteractionEvent,
    sendPageViewEvent
  } = useContext(GTMContext);
  const history = useHistory();
  const basenamePath = useBasenamePath();

  const sendView = useCallback(() => {
    if (user.data && sendPageViewEvent && user.isLoading === false) {
      sendViewEvent({
        sendPageViewEvent,
        erpSystem,
        loginLoading: user?.isLoading,
        loggedIn: user?.data?.loggedIn,
        userType: user?.data?.userType,
        userId: user?.data?.userId ? user?.data?.userId.toString() : "",
        userSegment: user?.data?.userSegment,
        customerNumber: user?.data?.customerNumber,
        organizationName: user?.data?.organizationName,
        userSalesChannel: user?.data?.userSalesChannel
      });
    }
  }, [sendPageViewEvent, user, erpSystem]);

  useEffect(() => {
    sendView();
  }, [sendView]);

  useEffect(() => {
    if (isReadyToSendEvents && sendProcessStartEvent) {
      sendProcessStartEvent({ processStepName: "1_chooseproductcategory" });
    }
  }, [isReadyToSendEvents, sendProcessStartEvent]);

  const handleCategoryClick = (category: any) => {
    sendProcessInteractionEvent({ processStepName: "2_chooseproductdetails" });
    const link = category.catId
      ? `${basenamePath}/categories/${category.basicForm}/?catId=${category.catId}`
      : `${basenamePath}/categories/${category.basicForm}`;
    history.push(link, category);
  };
  const renderCategories = () => {
    const result = [];
    for (const cat in categories) {
      if (categories[cat].length !== 0) {
        result.push(
          <Box marginBottom="sp8">
            <Box marginBottom="sp16">
              <CategoryTitleH4>{getCategoryTitle(cat, intl)}</CategoryTitleH4>
            </Box>
            <Box wrap={true} display="flex">
              {categories[cat].map((category, index) => {
                return (
                  <CategoryTile
                    key={index}
                    category={category}
                    index={index}
                    onClick={handleCategoryClick}
                  />
                );
              })}
            </Box>
          </Box>
        );
      }
    }
    return result;
  };
  return <Box data-testid="categories-screen">{renderCategories()}</Box>;
};

export default CategoriesScreen;
