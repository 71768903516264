import React, { FC, useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { Vector3 } from "three";
import { FormattedMessage } from "react-intl";
import { TextRodeContainer } from "./styles";
import { CylinderShape } from "./CylinderShape";

export type RodePreviewProps = {
  color?: string;
  diameter?: number | undefined;
  length?: number | undefined;
};

const INITIAL_RATIO_LENGTH = 1;
const INITIAL_RATIO_DIAMETER = 0.5;
const INITIAL_ZOOM = 70;

/* Renders 3d preview of the Rode based on entered values
 */
const RodePreview3d: FC<RodePreviewProps> = ({
  color,
  diameter,
  length,
}: RodePreviewProps) => {
  const [ratioDiameter, setRatioDiameter] = useState<number>(
    INITIAL_RATIO_DIAMETER
  );

  const [ratioLength, setRatioLength] = useState<number>(INITIAL_RATIO_LENGTH);

  const getRatioCylinder = (
    length: number | undefined,
    diameter: number | undefined
  ): number => {
    return 1 / Math.max(length || -Infinity, diameter || -Infinity);
  };

  useEffect(() => {
    const ratio = getRatioCylinder(length, diameter);
    if (ratio > 0) {
      if (diameter) {
        setRatioDiameter(Number(diameter) * ratio);
      }
      if (length) {
        setRatioLength(Number(length) * ratio);
      }
    } else {
      setRatioDiameter(INITIAL_RATIO_DIAMETER);
      setRatioLength(INITIAL_RATIO_LENGTH);
    }
  }, [length, diameter]);

  return (
    <>
      <Canvas orthographic camera={{ zoom: INITIAL_ZOOM }}>
        <ambientLight intensity={1.5} />
        <directionalLight position={new Vector3(-1, 5, -2)} />
        <CylinderShape
          diameter={ratioDiameter}
          length={ratioLength}
          color={color}
        />
      </Canvas>
      <TextRodeContainer>
        <p>
          <FormattedMessage
            id="step2.length"
            defaultMessage="Length(mm)"
            description="label of Length(mm) field"
          />
          : {length}
        </p>
        <p>
          <FormattedMessage
            id="step2.diameter"
            defaultMessage="Diameter(mm)"
            description="label of Diameter in the form"
          />
          : {diameter}
        </p>
      </TextRodeContainer>
    </>
  );
};

export { RodePreview3d };
