const shouldShow3dPreview: () => boolean = () => {
  // in production always return false
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    return false;
  }
  const params = new URLSearchParams(window.location.search.substring(1));
  const show3dParam = params.get("show3d");
  // removes ? at the begining
  // return false if the showw3d param is not passed
  if (show3dParam === null) {
    return false;
  }
  if (show3dParam === "true") {
    return true;
  }

  if (show3dParam === "false") {
    return false;
  }
  // return false by default
  return false;
};

export { shouldShow3dPreview };
