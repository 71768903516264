import styled from "styled-components";

export const DiameterMeasurement = styled.div`
  position: relative;
  left: 20px;
  height: 20px;
`;

export const LengthMeasurement = styled.div`
  position: relative;
  left: 190px;
  bottom: 100px;
  height: 20px;
`;
