import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { LanguageContext } from "@eriksdigital/atomic-ui/components";
import { GTMContext } from "@eriksdigital/gs-ga4";

import { Wrapper } from "./styles";
import { ModalError } from "./ModalError/ModalError";
import { useParams, useLocation } from "react-router-dom";
import ButtonBack from "../ButtonBack";
import { ContainerWrapper, Content } from "../../styles/styles";
import Results from "../Results";
import { transformResponseToDataForm } from "./utils";
import CustomizerScreenForm from "./ScreenForm";
import { useIntl } from "react-intl";
import { useRaffaella } from "@eriksdigital/raffaella";
import { ItemQuantityContext } from "providers/ItemQuantity";

export const CustomizerScreen = () => {
  const intl = useIntl();
  const [user] = useRaffaella("user");
  const [attributes, makeResourceCallAttributes] = useRaffaella("attributes");
  const [items, makeResourceCallItems] = useRaffaella("items");
  const { language, erpSystem } = useContext<any>(LanguageContext);
  const [showModal, setShowModal] = useState(false);
  const [attrs, setAttrs] = useState(null);
  const { id, basicForm: type } = useParams() as any;
  const { search } = useLocation();
  const catId = new URLSearchParams(search.substring(1)).get("catId");
  const { sendProcessFinishEvent, sendPageViewEvent } = useContext(GTMContext);
  const { updateQuantity } = useContext(ItemQuantityContext);

  const sendView = useCallback(() => {
    if (user.data !== null && sendPageViewEvent && user.isLoading === false) {
      sendPageViewEvent({
        pageUrl: window.location.href,
        pagePath: window.location.pathname,
        pageHostname: window.location.hostname,
        organizationId: user?.data?.customerNumber || "",
        organizationName: user?.data?.organizationName || "",
        userSalesChannel: user?.data?.userSalesChannel,
        userType: user?.data?.userType,
        userId: user?.data?.userId ? user?.data?.userId.toString() : "",
        userSegment: user?.data?.userSegment,
        loggedIn: user?.data?.loggedIn
      });
    }
  }, [sendPageViewEvent, user]);

  useEffect(() => {
    sendView();
  }, [sendView]);

  const getFormData = (data: any) => {
    const {
      length,
      lengthRange,
      width,
      colour,
      diameter,
      materialClass,
      isoName,
      thickness,
      eriksName,
      productType,
      internalDiameter,
      height,
      widthFilter,
      series
    } = data;

    return {
      ...(height ? { height: parseInt(height.value, 10) } : {}),
      ...(colour ? { colour: colour.value } : {}),
      ...(length ? { length: parseInt(length, 10) } : {}),
      ...(lengthRange ? { length: lengthRange } : {}),
      ...(width ? { width: parseInt(width, 10) } : {}),
      ...(widthFilter ? { width: parseInt(widthFilter.value, 10) } : {}),
      ...(widthFilter ? { widthFilter: parseInt(widthFilter.value, 10) } : {}),
      ...(materialClass
        ? { materialClass: materialClass && materialClass.value }
        : {}),
      ...(isoName ? { isoName: isoName.value } : {}),
      ...(eriksName ? { eriksName: eriksName.value } : {}),
      ...(productType && productType.value
        ? { productType: productType.value.toString() }
        : {}),
      ...(thickness ? { thickness: Number(thickness.value) } : {}),
      ...(diameter ? { diameter: Number(diameter.value) } : {}),
      ...(internalDiameter
        ? { internalDiameter: Number(internalDiameter.value) }
        : {}),
      ...(series ? { series: series.value } : {})
    };
  };

  const onSubmit = (values: any) => {
    if (sendProcessFinishEvent) {
      sendProcessFinishEvent({ processStepName: "3_showresult" });
    }
    makeResourceCallItems &&
      makeResourceCallItems({
        apiKey: "items",
        reqData: {
          type,
          lang: language,
          erpSystem: erpSystem.toUpperCase(),
          quantity: 1,
          ...(erpSystem === "ch" && {
            reconfigure: true
          }),
          ...(catId ? { catId } : {}),
          ...getFormData(values)
        }
      });

    updateQuantity({ erpPartNumber: "", quantity: 1 });
  };

  const getAttributes = useCallback(
    values => {
      const filter = {
        ...values.filter,
        ...(values.filter && values.filter.colour === 0 ? { colour: "" } : null)
      };

      makeResourceCallAttributes &&
        makeResourceCallAttributes({
          apiKey: "attributes",
          reqData: {
            type,
            lang: language,
            erpSystem: erpSystem.toUpperCase(),
            ...(erpSystem === "ch" && {
              reconfigure: true
            }),

            ...values,
            ...(values.colour === 0 ? { colour: "" } : null),
            ...filter
          }
        });
    },

    [makeResourceCallAttributes, type, language, erpSystem]
  );

  const loadAttributesData = useCallback(() => {
    getAttributes({
      type,
      lang: language,
      erpSystem: erpSystem.toUpperCase(),
      ...(catId ? { catId } : {}),
      ...(id ? { erpPartNumber: id } : {})
    });
  }, [erpSystem, getAttributes, language, type, id, catId]);

  const setAttributesData = (fields: any) => {
    if (fields) {
      const fieldsData = transformResponseToDataForm(fields);
      setAttrs(fieldsData as any);
    }
  };

  useEffect(() => {
    if (!!attributes.data === true) {
      setAttributesData(attributes.data);
    }
  }, [attributes.data, attributes.isLoading]);

  useEffect(() => {
    loadAttributesData();
  }, [loadAttributesData]);

  useEffect(() => {
    if (items.isLoading) {
      window.scroll(window.scrollX, window.scrollY + 150);
    }
  }, [items.isLoading]);

  const getModalText = () => {
    if (erpSystem === "ch") {
      return intl.formatMessage({
        id: "step2.CHmodalErrorMessageSheet",
        defaultMessage: "Begin by selecting the thickness",
        description: "label of modal message error for required thickness"
      });
    }
    if (type === "rod") {
      return intl.formatMessage({
        id: "step2.modalErrorMessageRod",
        defaultMessage:
          "Begin by selecting the material OR diameter of your rod. Both are required inputs.",
        description:
          "abel of message error for required material OR thickness in rod"
      });
    }
    return intl.formatMessage({
      id: "step2.modalErrorMessageSheet",
      defaultMessage:
        "Begin by selecting the material OR thickness of your sheet. Both are required inputs.",
      description: "label of message error for required material OR thickness"
    });
  };

  return (
    <Fragment>
      <ContainerWrapper>
        <ButtonBack />
        <Content>
          <Wrapper data-testid={type}>
            <CustomizerScreenForm
              onSubmit={onSubmit}
              attrs={attrs}
              getAttributes={getAttributes}
              modalError={setShowModal}
            />
            <ModalError
              show={showModal}
              text={getModalText()}
              buttonText={intl.formatMessage({
                id: "step2.modalErrorButton",
                defaultMessage: "Let's go!",
                description: "label of close error modal"
              })}
              onClick={() => setShowModal(false)}
            />
          </Wrapper>
        </Content>
      </ContainerWrapper>
      {items?.data?.items && !items.isLoading ? (
        <Results
          items={items?.data?.items}
          type={type}
          loading={items.isLoading}
        />
      ) : null}
    </Fragment>
  );
};

export default CustomizerScreen;
