import { MessageDescriptor } from "react-intl";

export const createLabel = (
  id: string,
  formatter: (messageDescriptor: MessageDescriptor) => string
) => {
  return formatter({
    id: `step2.${id}`,
    defaultMessage: `${id}`,
    description: `${id}-field`,
  });
};
