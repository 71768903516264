export const removeLastDirectoryPartOf = (
  location: { [k: string]: string },
  params: { [p: string]: string },
  baseName: string
) => {
  let url: string = `${baseName}/categories`;
  delete params.id;
  for (let paramKey in params) {
    url = `${url}/${params[paramKey]}`;
  }
  if (location.search !== "") {
    return `${url}${location.search}`;
  }
  return url;
};
