export const mutators = {
  setPositiveValue: ([event], state, { changeValue }) => {
    const value = parseInt(event.target.value);
    if (value > 0) {
      changeValue(state, event.target.name, () => value);
    }
    if (!value) {
      changeValue(state, event.target.name, () => "");
    }
  },
  setValue: ([field, value], state, { changeValue }) => {
    changeValue(state, field, () => value);
  },
};
