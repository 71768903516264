import { ResourcesMap } from "@eriksdigital/raffaella";

export const config: ResourcesMap = {
  user: {
    apiEndpoint: process.env.WEBSHOP_INTEGRATION_API
      ? `${process.env.WEBSHOP_INTEGRATION_API}/cookies/isLoggedIn`
      : "https://api.test.eriksdigital.com/guided-selling-webshop-integration/api/cookies/isLoggedIn",
    method: "GET",
  },
  availableLanguages: {
    apiEndpoint: ":storeUrl/wcs/resources/store/:storeId/available-languages",
    method: "GET",
    transformDTO: (DTO) => ({ data: Object.keys(DTO) }),
  },
  salesOrganization: {
    apiEndpoint: process.env.OPEN_FAAS_URL
      ? `${process.env.OPEN_FAAS_URL}/sales-organizations`
      : "https://fa-ed-eu-tst-gb-cicd.azurewebsites.net/api/sales-organizations",
    method: "GET",
  },
  attributes: {
    apiEndpoint: process.env.PLASTICS_API
      ? `${process.env.PLASTICS_API}/attributes`
      : "https://api.test.eriksdigital.com/gs-plastic-customizer/api/attributes",
    method: "GET",
  },
  items: {
    apiEndpoint: process.env.PLASTICS_API
      ? `${process.env.PLASTICS_API}/items`
      : "https://api.test.eriksdigital.com/gs-plastic-customizer/api/items",
    method: "GET",
  },
  stockLevels: {
    apiEndpoint: process.env.ERPAL_INTEGRATION_API
      ? `${process.env.ERPAL_INTEGRATION_API}/stock`
      : "https://api.test.eriksdigital.com/guided-selling-erpal-integration/api/stock",
    method: "GET",
  },
  price: {
    apiEndpoint: process.env.ERPAL_INTEGRATION_API
      ? `${process.env.ERPAL_INTEGRATION_API}/prices/customized?configuratorType=PLASTICS`
      : "https://api.test.eriksdigital.com/guided-selling-erpal-integration/api/prices/customized?configuratorType=PLASTICS",
    method: "POST",
  },
  addToCart: {
    apiEndpoint: process.env.PLASTICS_API
      ? `${process.env.PLASTICS_API}/addToCart`
      : "https://api.test.eriksdigital.com/gs-plastic-customizer/api/addToCart",
    method: "POST",
  },
  shoppingCart: {
    apiEndpoint: process.env.WEBSHOP_INTEGRATION_API
      ? `${process.env.WEBSHOP_INTEGRATION_API}/cart`
      : "https://api.test.eriksdigital.com/guided-selling-webshop-integration/api/cart",
    method: "GET",
  },
};
