import React, { useCallback, useContext, useEffect } from "react";
import { GTMContext } from "@eriksdigital/gs-ga4";
import {
  Box,
  Button,
  Divider,
  LanguageContext,
  P
} from "@eriksdigital/atomic-ui/components";
import { useRaffaella } from "@eriksdigital/raffaella";
import { CheckIcon } from "@eriksdigital/atomic-ui/components/Icons";

import { IconWrapper, ListI, Title, UList } from "./styles";
import useBasenamePath from "../../utils/useBasenamePath";
import { Redirect, useHistory } from "react-router-dom";
import { sendViewEvent } from "../../utils/utils";
import { FormattedMessage } from "react-intl";

const IntroScreen: React.FC = () => {
  const history = useHistory();
  const basenamePath = useBasenamePath();
  const { erpSystem } = useContext<any>(LanguageContext);
  const { isReadyToSendEvents, sendPageViewEvent } = useContext<any>(
    GTMContext
  );
  const [user] = useRaffaella("user");

  const sendView = useCallback(() => {
    if (user.data && sendPageViewEvent && user.isLoading === false) {
      sendViewEvent({
        isReadyToSendEvents,
        sendPageViewEvent,
        erpSystem,
        loginLoading: user?.isLoading,
        loggedIn: user?.data?.loggedIn,
        userType: user?.data?.userType,
        userId: user?.data?.userId ? user?.data?.userId.toString() : "",
        userSegment: user?.data?.userSegment,
        customerNumber: user?.data?.customerNumber,
        organizationName: user?.data?.organizationName,
        userSalesChannel: user?.data?.userSalesChannel
      });
    }
  }, [isReadyToSendEvents, sendPageViewEvent, user, erpSystem]);

  useEffect(() => {
    sendView();
  }, [sendView]);

  const onClickButton = () => {
    history.push(`${basenamePath}/categories`);
  };

  if (erpSystem === "ch") {
    return <Redirect to={`${basenamePath}/categories`} />;
  }

  return (
    <Box data-testid="intro-screen">
      <Box direction="column" width="100%">
        <Divider />
        <Box marginTop="sp16" marginBottom="sp12">
          <Title>
            <FormattedMessage
              id="intro.title"
              defaultMessage="Easily purchase made-to-order plastics"
              description="Title display in the intro"
            />
          </Title>
        </Box>

        <Box marginBottom="sp4">
          <P>
            <FormattedMessage
              id="intro.description"
              defaultMessage="Easily purchase made-to-order plastics"
              description="Title display in the intro"
            />
          </P>
        </Box>

        <UList>
          <ListI>
            <IconWrapper as={CheckIcon} />
            <FormattedMessage
              id="intro.item-1"
              defaultMessage="Custom-made sheets and rods"
              description="Title display for item 1"
            />
          </ListI>
          <ListI>
            <IconWrapper as={CheckIcon} />
            <FormattedMessage
              id="intro.item-2"
              defaultMessage="Immediate quotation"
              description="Title display for item 2"
            />
          </ListI>
          <ListI>
            <IconWrapper as={CheckIcon} />
            <FormattedMessage
              id="intro.item-3"
              defaultMessage="24/7 overview on availability"
              description="Title display for item 3"
            />
          </ListI>
          <ListI>
            <IconWrapper as={CheckIcon} />
            <FormattedMessage
              id="intro.item-4"
              defaultMessage="Order efficiently through the ERIKS webshop"
              description="Title display for item 4"
            />
          </ListI>
        </UList>

        <Box marginTop="sp8" paddingY="sp12">
          <Button
            onClick={onClickButton}
            data-testid="intro-next"
            id="start-plastics-configurator"
            stretch="auto"
          >
            <FormattedMessage
              id="intro.button"
              defaultMessage="Start Plastics Configurator"
              description="Start Plastics Configurator"
            />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default IntroScreen;
