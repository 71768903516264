import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sp32};
  margin-bottom: ${({ theme }) => theme.spacing.sp32};
  border: 1px solid ${({ theme }) => theme.colors.border.gray};
`;

export const LoadingWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.sp32};
`;
