export const transformResponseToDataForm = fields => {
  const data = {};
  if (fields) {
    for (const [key, val] of Object.entries(fields)) {
      let field = {};
      if (Array.isArray(val)) {
        field = {
          ...field,
          options: val.map(option => option.value)
        };
      } else {
        field = val;
      }
      data[key] = field;
    }
  }
  return data;
};

export const transformDataFormToRequest = values => {
  let result = {};
  Object.keys(values).forEach(field => {
    const value = values[field]?.value;
    const id = values[field]?.id;
    if (id) {
      if (id === "productType" || id === "series") {
        // these two should always be sent as string
        result[field] = value;
      } else {
        result[field] = isNaN(value) ? value : Number(value);
      }
    }
  });
  return result;
};
