import React, {
  createContext,
  useReducer,
  ReactNode,
  useEffect,
  useCallback,
} from "react";

export interface NotificationContextInterface {
  notifications?: Notification[];
  dismissNotification?: Function;
  createNotification?: Function;
}

type Notification = {
  id: string;
  variant?: "success" | "warning" | "info" | "error";
  message?: string | ReactNode;
  autoDismiss?: boolean;
};

type Action = {
  type: string;
  payload: Notification;
};
type TimerAction = {
  type: string;
  payload: number;
};

export const NotificationContext = createContext<
  Partial<NotificationContextInterface>
>({});

const reducer = (state: Array<Notification>, action: Action) => {
  switch (action.type) {
    case "CREATE_NOTIFICATION":
      return [...state, action.payload];
    case "DISMISS_NOTIFICATION":
      return state.filter(
        (notification) => notification.id !== action.payload.id
      );
    default:
      return state;
  }
};

const timersReducer = (state: number[], action: TimerAction) => {
  switch (action.type) {
    case "ADD_TIMER":
      return [...state, action.payload];
    default:
      return state;
  }
};

export const NotificationProvider: React.FC<{ defaultDelay?: number }> = ({
  children,
  defaultDelay = 3000,
}) => {
  const [state, dispatch] = useReducer(reducer, []);
  const [timers, timerDispatch] = useReducer(timersReducer, []);

  const createNotification = useCallback(
    (notification: Notification, delay: number) => {
      dispatch({
        type: "CREATE_NOTIFICATION",
        payload: notification,
      });

      if (notification.autoDismiss) {
        const timer = window.setTimeout(() => {
          dispatch({
            type: "DISMISS_NOTIFICATION",
            payload: {
              id: notification.id,
            },
          });
        }, delay || defaultDelay);

        timerDispatch({
          type: "ADD_TIMER",
          payload: timer,
        });
      }
    },
    [defaultDelay]
  );

  const dismissNotification = (id: string) => {
    dispatch({
      type: "DISMISS_NOTIFICATION",
      payload: { id },
    });
  };

  const clearAllTimers = useCallback(() => {
    timers.forEach((timer) => clearTimeout(timer));
  }, [timers]);

  useEffect(() => {
    return () => clearAllTimers();
  }, [clearAllTimers]);

  const context = {
    createNotification,
    dismissNotification,
    notifications: state,
  };

  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  );
};
