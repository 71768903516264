import React from "react";
import {
  DiameterMeasurement,
  InternalDiameterMeasurement,
  LengthMeasurement,
  TubePreviewWrapper
} from "./styles";
import TubeImage from "../../../../TubeImage";
import TubeDiameterImage from "../../../../TubeDiameterImage";

interface Props {
  color?: string;
  thickness?: string;
  length?: string;
  width?: string;
  diameter?: string;
  internalDiameter?: string;
}

const TubePreview = (props: Props) => {
  const { color, diameter, length, internalDiameter } = props;
  return (
    <TubePreviewWrapper>
      <TubeImage color={color} />
      <LengthMeasurement>{length}</LengthMeasurement>
      <DiameterMeasurement>{diameter}</DiameterMeasurement>
      <TubeDiameterImage />
      <InternalDiameterMeasurement>
        {internalDiameter}
      </InternalDiameterMeasurement>
    </TubePreviewWrapper>
  );
};

export default TubePreview;
