import React from "react";

export const RodImage = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="257"
      height="207"
      viewBox="0 0 257 207"
      data-testid="rod-image"
    >
      <g fill={color} fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-234 -254)">
          <g transform="translate(238 242)">
            <path
              stroke="#979797"
              d="M104.01-6.03c5.93-9.297 15.946-14.491 30.052-15.582 14.106-1.091 26.753 3.554 37.94 13.936l4.994 233.919c.327-.053.136-1.469-.57-4.247-2.393-9.399-8.885-15.979-11.412-19-11.073-13.233-27.324-11.282-33.015-11.108-6.249.191-21.556 5.716-30.1 22.346-2.055 4.002-2.652 12.038-1.79 24.108L104.01-6.03z"
              opacity="0.816"
              transform="rotate(54 138.439 108.287)"
            />
            <path
              stroke="#979797"
              d="M39.5 218c10.497 0 20.36-3.628 27.304-10.44C73.928 200.573 78 190.266 78 179.5c0-21.263-17.237-38.5-38.5-38.5S1 158.237 1 179.5 18.237 218 39.5 218z"
            />
            <path
              fill="#979797"
              fillRule="nonzero"
              d="M77.415 178.063a4.5 4.5 0 11-4.447 5.022l-67.18.33a4.5 4.5 0 11-.006-1l67.18-.33a4.5 4.5 0 014.453-4.022z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RodImage;
