import styled from "styled-components";
import { breakpoints } from "@eriksdigital/atomic-ui/styles";
import { Theme } from "../../global.types";

export const ButtonBackWrapper = styled.div`
  margin-left: 0;

  ${breakpoints.tabletLandscape`
    margin: ${({ theme }: { theme: Theme }) => theme.spacing.sp16};
  `}
`;
