import React from "react";

export const TubeDiameterImage = () => {
  return (
    <svg
      data-testid="tube-diameter-image"
      width="183"
      height="213"
      viewBox="0 0 183 213"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="92" cy="123" r="60.5" stroke="#979797" />
      <circle cx="91.5" cy="121.5" r="91" stroke="#979797" />
      <line x1="32" y1="125.5" x2="153" y2="125.5" stroke="black" />
      <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="20" stroke="black" />
      <line x1="4.37114e-08" y1="9.5" x2="183" y2="9.50002" stroke="black" />
      <line x1="182.5" y1="2.18557e-08" x2="182.5" y2="20" stroke="black" />
    </svg>
  );
};

export default TubeDiameterImage;
