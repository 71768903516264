import React, { lazy, Suspense } from "react";
import { Category } from "../../types";
// @ts-ignore
import { Box, LoadingIndicator } from "@eriksdigital/atomic-ui/components";
import { getCategoryNameString } from "./utils";
import { useIntl } from "react-intl";
import { StyledButton, StyledButtonText } from "../../styles/styles";
//TODO lazyload images
const ElastomerSheetImage = lazy(() => import("../ImageElastomerSheet"));
const SheetImage = lazy(() => import("../ImageSheet"));
const TubeImage = lazy(() => import("../ImageTube"));
const RodImage = lazy(() => import("../ImageRode"));
const GlassFabricsImage = lazy(() => import("../ImageGlassFabricFilm"));
const ProfileImage = lazy(() => import("../ImageProfile"));
const GrateImage = lazy(() => import("../ImageGrate"));
const FibreSheetImage = lazy(() => import("../ImageFiberSeal"));
const PtfeSheetImage = lazy(() => import("../ImagePTFESeal"));
const GraphiteSheetImage = lazy(() => import("../ImageGraphiceSeal"));
const ThermalInsulationImage = lazy(() => import("../ImageThermalInsulation"));
const StripsImage = lazy(() => import("../ImageStrips"));
const SlideImage = lazy(() => import("../ImageSlide"));

const imageMapper: {
  [k: string]: React.LazyExoticComponent<() => JSX.Element>;
} = {
  elastomerPlates: ElastomerSheetImage,
  sheet: SheetImage,
  tube: TubeImage,
  rod: RodImage,
  glassFabricFilms: GlassFabricsImage,
  profile: ProfileImage,
  grate: GrateImage,
  fibreSheet: FibreSheetImage,
  ptfeSheet: PtfeSheetImage,
  graphiteSheets: GraphiteSheetImage,
  thermalInsulationBoards: ThermalInsulationImage,
  strips: StripsImage,
  slides: SlideImage,
};

type Props = {
  category: Category;
  index: number;
  onClick: (cat: Category) => void;
};

const CategoryTile = ({ category, index, onClick }: Props) => {
  const intl = useIntl();
  const isFifth = index % 4 === 0;
  const leftMargin = isFifth ? "0px" : "32px";
  const width = isFifth ? `${268 - 32}px` : "268px";
  const ImageComponent = imageMapper[category.name];
  return (
    <StyledButton
      data-testid={`${category.basicForm}-category`}
      key={`${category.basicForm}-${category.catId}`}
      id={`${category.basicForm}-${category.catId}`}
      variant="ghost"
      onClick={() => onClick(category)}
      leftMargin={`margin-left: ${leftMargin}`}
    >
      <Box width={width} display="flex" alignItems="center">
        <Box
          height="64px"
          width="64px"
          shape="rounded"
          overflow="hidden"
          flex="0 0 auto"
        >
          <Suspense fallback={<LoadingIndicator />}>
            <ImageComponent />
          </Suspense>
        </Box>
        <StyledButtonText>
          {getCategoryNameString(category.name, intl)}
        </StyledButtonText>
      </Box>
    </StyledButton>
  );
};

export { CategoryTile };
