import React, { useEffect, useState } from "react";
import { StockIndicator } from "@eriksdigital/atomic-ui/components";
import { DeliveryDate } from "./style";
import { FormattedMessage } from "react-intl";

type statuses = {
  CompletelyOnStock: string;
  NothingOnStock: string;
  Loading: string;
  Unavailable: string;
};

const statuses = {
  CompletelyOnStock: {
    status: "inStock",
    message: (
      <FormattedMessage
        id="stock-info.CompletelyOnStock"
        defaultMessage="In Stock"
        description="Label of Not price in search results section"
      />
    )
  },
  NothingOnStock: {
    status: "outOfStock",
    message: (
      <FormattedMessage
        id="stock-info.NothingOnStock"
        defaultMessage="Out of stock"
        description="Label of out of stock in search results section"
      />
    )
  },
  Loading: {
    status: "loading",
    message: (
      <FormattedMessage
        id="stock-info.loading"
        defaultMessage="Loading stock information"
        description="Label of loading in search results section"
      />
    )
  },
  Unavailable: {
    status: "infoUnavailable",
    message: (
      <FormattedMessage
        id="stock-info.unavailable"
        defaultMessage="Stock information unavailable"
        description="Label of loading in search results section"
      />
    )
  }
};

export const StockInformation = (data: any) => {
  const [status, setStatus] = useState<keyof statuses>("Loading");
  const [stockLoading, setStockLoading] = useState(true);

  useEffect(() => {
    if (!!data.data) {
      setStatus(data.data.available);
      setStockLoading(false);
    }
  }, [data]);

  if (!stockLoading && !!data === false) {
    return (
      <StockIndicator
        status={statuses["Unavailable"]?.status}
        stockMessage={statuses["Unavailable"]?.message}
      />
    );
  }

  return (
    <>
      {status === "NothingOnStock" && (
        <DeliveryDate>
          <FormattedMessage
            id="stock-info.deliveryDate"
            defaultMessage="Estimated delivery date"
            description="Label of delivery date in search results section"
          />{" "}
          {data.data.deliveryDate}
        </DeliveryDate>
      )}
      {status && (
        <StockIndicator
          status={statuses[status]?.status}
          stockMessage={statuses[status]?.message}
        />
      )}
    </>
  );
};
