import styled from "styled-components";

export const DiameterMeasurement = styled.div`
  position: relative;
  left: 5px;
  height: 20px;
`;

export const LengthMeasurement = styled.div`
  position: relative;
  left: 70px;
  bottom: 85px;
  height: 20px;
`;

export const TubePreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InternalDiameterMeasurement = styled.div`
  position: relative;
  left: 5px;
  top: -115px;
`;
