import React, { useContext } from "react";
import { Button, LanguageContext } from "@eriksdigital/atomic-ui/components";
import { FormattedMessage } from "react-intl";

const LogInStatus = ({ user, isLoading }: any) => {
  const { erpSystem } = useContext<any>(LanguageContext);
  const { loginUrl, loggedIn, isGuestUser } = user;

  if (isLoading) return <div data-testid="login-loading-status"></div>;

  const shouldShowLoginMessage = () => {
    if (erpSystem === "ch") {
      return !loggedIn;
    }
    return isGuestUser;
  };

  return (
    <div data-testid="login-status">
      {!shouldShowLoginMessage() ? (
        <FormattedMessage
          id="login-status.logged-in"
          defaultMessage="Logged in"
          description="status of loged in user"
        />
      ) : (
        <Button variant="ghost" id="login" as="a" href={loginUrl}>
          <FormattedMessage
            id="login-status.go-to-login"
            defaultMessage="Login for prices"
            description="Login for prices"
          />
        </Button>
      )}
    </div>
  );
};

export default LogInStatus;
