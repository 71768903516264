import { IntlShape } from "react-intl";

const getCategoryNameString = (categoryName: string, intl: IntlShape) => {
  return intl.formatMessage({
    id: `categories.${categoryName}.label`,
    defaultMessage: `${categoryName}`,
    description: "Labels of categories screen",
  });
};

export { getCategoryNameString };
