import React from "react";
import {
  LoadingIndicator,
  Price as PriceUI
} from "@eriksdigital/atomic-ui/components";
import { NoPriceMessage } from "../Item/styles";
import { FormattedMessage, useIntl } from "react-intl";
import { useRaffaella } from "@eriksdigital/raffaella";

export type PriceResponse = {
  currency: string | null;
  erpPartNumber: string;
  itemId: string | null;
  price: string | null;
  priceRaw: number | null;
  error?: string;
  isLoading?: boolean;
};

interface Item {
  erpPartNumber: string;
  configuration: string;
  width?: number;
  length?: number;
}

interface PriceProps {
  data?: {
    netValue?: string;
    rawPrice?: string;
  } | null;
}

const Price = ({ data }: PriceProps) => {
  const intl = useIntl();
  const [price] = useRaffaella("price");
  const { isLoading, error } = price;
  const rawPrice = data && data.rawPrice;
  const noPriceMessage = (
    <NoPriceMessage marginTop="auto" marginBottom="auto">
      <FormattedMessage
        id="results.noPriceMessage"
        defaultMessage="No price can be calculated for this item, please reach out to your contact person"
        description="Label of Not price in search results section"
      />
    </NoPriceMessage>
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return noPriceMessage;
  }

  if (rawPrice === "0.00" || rawPrice === "0,00") {
    return noPriceMessage;
  }

  if (
    (data !== null && !isLoading) ||
    (rawPrice !== "0.00" && rawPrice !== "0,00")
  ) {
    return (
      <>
        <PriceUI
          data-testid="price"
          currentPrice={`${data?.netValue}`}
          itemUnit=""
          perUnitLabel={intl.formatMessage({
            id: "results.priceLabel",
            defaultMessage: "Item total",
            description: "label of total item  in the search result section"
          })}
          variant="main"
        />
      </>
    );
  }

  return <></>;
};

export { Price };
