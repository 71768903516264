import React, { FC, useRef } from "react";

export type CylinderShapeProperties = {
  color?: string;
  diameter?: number;
  length?: number;
};

const CylinderShape: FC<CylinderShapeProperties> = ({
  color,
  diameter,
  length,
}: CylinderShapeProperties) => {
  const ref = useRef();
  return (
    <mesh ref={ref} position={[0, 0, 0]} rotation={[0.6, 0.3, -0.6]}>
      <cylinderGeometry args={[diameter, diameter, length, 64]} />
      <meshStandardMaterial color={color} />
    </mesh>
  );
};

export { CylinderShape };
