import styled from "styled-components";
import { H1, Box } from "@eriksdigital/atomic-ui/components";
import { breakpoints } from "@eriksdigital/atomic-ui/styles";

export const TitleWrapper = styled(H1)`
  line-height: ${({ theme }) => theme.fonts.lineHeight.text};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft20};
  ${breakpoints.tabletLandscape`
    line-height: ${({ theme }: any) => theme.fonts.lineHeight.heading};
    font-size: ${({ theme }: any) => theme.fonts.fontSize.ft40};
  `}
`;

export const Description = styled(Box)`
  margin: ${({ theme }) => theme.spacing.sp16} 0;
  ${breakpoints.tabletLandscape`
    margin: ${({ theme }: any) => theme.spacing.sp32} 0;
  `}
`;
