import React, { createContext, useCallback, useReducer } from "react";

type Action = {
  type: string;
  payload: object;
};

interface State {
  erpPartNumber: string;
  quantity: number;
}

interface ItemQuantityContextInterface {
  state: State;
  updateQuantity(state: Partial<State>): void;
}

const defaultState: State = {
  erpPartNumber: "",
  quantity: 1
};

export const ItemQuantityContext = createContext<ItemQuantityContextInterface>({
  state: defaultState,
  updateQuantity(): void {}
});

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "UPDATE_STATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const ItemQuantityProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const dispatchUpdateState = (state: State) => {
    dispatch({
      type: "UPDATE_STATE",
      payload: state
    });
  };

  const updateQuantity = useCallback((state: State) => {
    dispatchUpdateState(state);
  }, []);

  const context = {
    state,
    updateQuantity
  };

  return (
    <ItemQuantityContext.Provider value={context}>
      {children}
    </ItemQuantityContext.Provider>
  );
};
