import React, { useContext } from "react";
import { ImageContainer } from "../../styles";
import { translatedColorsMap } from "../../ColorPalette/colorsMap";
import { LanguageContext } from "@eriksdigital/atomic-ui/components";
import { SheetPreview3d } from "./SheetPreview3d";
import TubePreview from "../Preview/TubePreview";
import { RodePreview3d } from "./RodePreview3d";

export const Preview3D = ({ type, values }) => {
  const { language } = useContext(LanguageContext);

  const color = translatedColorsMap[language][values.colour?.value] || "white";
  const diameter = values?.diameter ? `${values.diameter?.value} mm` : "";
  const internalDiameter = values?.internalDiameter
    ? `${values?.internalDiameter?.value} mm`
    : "";
  const length = values?.length ? `${values?.length} mm` : "";

  return (
    <ImageContainer data-testid={`image-${type}`}>
      {type === "rod" && (
        <RodePreview3d
          color={color}
          diameter={values.diameter?.value}
          length={values?.length}
        />
      )}
      {type === "sheet" && (
        <SheetPreview3d
          data-testid="sheet-3d"
          color={color}
          thickness={values?.thickness?.value}
          length={values?.length}
          width={values?.width}
        />
      )}
      {type === "tube" && (
        <TubePreview
          data-testid="tube-3d"
          color={color}
          diameter={diameter}
          length={length}
          internalDiameter={internalDiameter}
        />
      )}
    </ImageContainer>
  );
};
