export const isValidTextField = (field, value, attrs) => {
  if (attrs[field]) {
    const { minValue, maxValue } = attrs[field];
    return parseInt(value) >= minValue && parseInt(value) <= maxValue;
  }
};

const requiredFieldText = intl =>
  intl.formatMessage({
    id: "step2.requiredFieldText",
    defaultMessage: "The field is required",
    description: "label of validation field required"
  });
const validationWidthText = intl =>
  intl.formatMessage({
    id: "step2.validationWidth",
    defaultMessage:
      "Please enter a width which is equal to or smaller than the length.",
    description: "label of validation width"
  });
const validationLengthText = intl =>
  intl.formatMessage({
    id: "step2.validationLength",
    defaultMessage:
      "Please enter a length which is equal to or higher than the width.",
    description: "label of validation length"
  });

export const isWidthBiggerThanLength = values => {
  const { length, width } = values;
  if (length && width) {
    return width > length;
  }
};

export const placeholderTextField = (field, attrs, useIntl) => {
  if (attrs[field]) {
    const rangeFieldText = useIntl.formatMessage({
      id: "step2.rangeFieldText",
      defaultMessage: "Type a value",
      description: "label of validation field required"
    });
    const { minValue, maxValue } = attrs[field];

    if (minValue && maxValue) {
      return rangeFieldText + " ≥ " + minValue + " & ≤ " + maxValue;
    }
  }
  return useIntl.formatMessage({
    id: "step2.selectPlaceholder",
    defaultMessage: "Type / Select",
    description: "label of Type / Select placeholder field"
  });
};

const validation = (values, attrs, strings, isCh) => {
  const errors = {};
  if (!values.eriksName) errors.eriksName = requiredFieldText(strings);
  if (
    isColorRequired(attrs.colour?.options) &&
    (!values.colour || (values.colour && !values.colour.value))
  )
    errors.colour = requiredFieldText(strings);
  if (!values.isoName) errors.isoName = requiredFieldText(strings);
  if (!isCh && !isMaterialValid(values.materialClass, attrs))
    errors.materialClass = requiredFieldText(strings);

  return errors;
};

export const isColorRequired = colorOptions => colorOptions.length > 0;

export const isMaterialValid = (materialClass, attrs) => {
  return materialClass || !attrs.materialClass.options.length ? true : false;
};
const validationProfile = (values, intl, attrs) => {
  const errors = validation(values, attrs, intl);
  if (attrs?.thickness?.options?.length > 0 && !values.thickness) {
    errors.thickness = requiredFieldText(intl);
  }
  if (attrs?.productType?.options?.length > 0 && !values.productType) {
    errors.productType = requiredFieldText(intl);
  }
  if (!values.widthFilter) {
    errors.widthFilter = requiredFieldText(intl);
  }
  if (!values.lengthRange) {
    errors.lengthRange = requiredFieldText(intl);
  }
  if (!isValidTextField("lengthRange", values.lengthRange, attrs)) {
    errors.lengthRange = placeholderTextField("lengthRange", attrs, intl);
  }
  if (!values.height) {
    errors.height = requiredFieldText(intl);
  }

  const { materialClass: remove, ...rest } = errors;
  return rest;
};

const validationSheet = (values, intl, attrs, isCh) => {
  const lengthFieldCondition = isCh
    ? values.thickness && !values.length
    : values.thickness && values.materialClass && !values.length;
  const widthFieldCondition = isCh
    ? values.thickness && !values.width
    : values.thickness && values.materialClass && !values.width;
  const errors = validation(values, attrs, intl, isCh);
  if (isWidthBiggerThanLength(values)) {
    errors.width = validationWidthText(intl);
    errors.length = validationLengthText(intl);
  }
  if (!values.thickness) errors.thickness = requiredFieldText(intl);
  if (
    values.thickness &&
    !isValidTextField("lengthRange", values.length, attrs)
  )
    errors.length = placeholderTextField("lengthRange", attrs, intl);
  if (values.thickness && !isValidTextField("widthRange", values.width, attrs))
    errors.width = placeholderTextField("widthRange", attrs, intl);
  if (lengthFieldCondition) errors.length = requiredFieldText(intl);
  if (widthFieldCondition) errors.width = requiredFieldText(intl);

  return errors;
};
// in order to pass sonar cube duplicate code rule we have to expand validation to exclude fields for ch, and not just validate
// all fields again
const validationSheetCH = (values, intl, attrs) =>
  validationSheet(values, intl, attrs, true);

const validationGrate = (values, intl, attrs, isCh) => {
  const errors = validationSheet(values, intl, attrs, isCh);

  if (!values.productType) errors.productType = requiredFieldText(intl);

  return errors;
};

const validationGrateCH = (values, intl, attrs) => {
  return validationGrate(values, intl, attrs, true);
};

const validationRod = (values, strings, attrs, isCh) => {
  const lengthRequiredCondition = isCh
    ? values.diameter && !values.length
    : values.diameter && values.materialClass && !values.length;
  const lengthRangeCondition = isCh
    ? values.diameter && !isValidTextField("lengthRange", values.length, attrs)
    : values.diameter &&
      values.materialClass &&
      !isValidTextField("lengthRange", values.length, attrs);
  const errors = validation(values, attrs, strings, isCh);

  if (!values.diameter) errors.diameter = requiredFieldText(strings);
  if (lengthRangeCondition)
    errors.length = placeholderTextField("lengthRange", attrs, strings);

  if (lengthRequiredCondition) errors.length = requiredFieldText(strings);

  return errors;
};

const validationRodCH = (values, strings, attrs) => {
  return validationRod(values, strings, attrs, true);
};

const validationTube = (values, strings, attrs, isCh) => {
  const errors = validationRod(values, strings, attrs, isCh);

  if (!values.internalDiameter)
    errors.internalDiameter = requiredFieldText(strings);

  return errors;
};

const validationTubeCH = (values, strings, attrs) => {
  return validationTube(values, strings, attrs, true);
};

const validationGasket = (values, intl, attrs) => {
  const errors = {};
  if (!values.series) {
    errors.series = requiredFieldText(intl);
  }
  if (!values.productType) {
    errors.productType = requiredFieldText(intl);
  }
  if (!values.thickness) {
    errors.thickness = requiredFieldText(intl);
  }
  if (isWidthBiggerThanLength(values)) {
    errors.width = validationWidthText(intl);
    errors.length = validationLengthText(intl);
  }
  if (!isValidTextField("lengthRange", values.length, attrs)) {
    errors.length = placeholderTextField("lengthRange", attrs, intl);
  }
  if (!isValidTextField("widthRange", values.width, attrs)) {
    errors.width = placeholderTextField("widthRange", attrs, intl);
  }

  return errors;
};

const validationElastomerAndInsulation = (values, intl, attrs) => {
  const errors = {};
  if (!values.series) {
    errors.series = requiredFieldText(intl);
  }
  if (!values.productType) {
    errors.productType = requiredFieldText(intl);
  }
  if (!values.thickness) {
    errors.thickness = requiredFieldText(intl);
  }
  if (isWidthBiggerThanLength(values)) {
    errors.width = validationWidthText(intl);
    errors.length = validationLengthText(intl);
  }
  if (!isValidTextField("lengthRange", values.length, attrs)) {
    errors.length = placeholderTextField("lengthRange", attrs, intl);
  }
  if (!isValidTextField("widthRange", values.width, attrs)) {
    errors.width = placeholderTextField("widthRange", attrs, intl);
  }
  if (attrs.colour.options.length > 0 && !values.colour) {
    errors.colour = requiredFieldText(intl);
  }
  return errors;
};
const gasketCatIds = ["AFD100201", "AFD100301", "AFD100401"];
const elastomericCatIds = ["AFD100105"];
const insulationCatIds = ["ISM04"];

export const getValidationForForm = (basicForm, catId, erpSystem) => {
  switch (basicForm) {
    case "sheet": {
      if (insulationCatIds.findIndex(id => catId === id) > -1) {
        return validationElastomerAndInsulation;
      }
      if (gasketCatIds.findIndex(id => catId === id) > -1) {
        return validationGasket;
      }
      if (elastomericCatIds.findIndex(id => catId === id) > -1) {
        return validationElastomerAndInsulation;
      }
      if (erpSystem === "ch") {
        return validationSheetCH;
      }
      return validationSheet;
    }
    case "rod": {
      if (erpSystem === "ch") {
        return validationRodCH;
      }
      return validationRod;
    }
    case "grate": {
      if (erpSystem === "ch") {
        return validationGrateCH;
      }
      return validationGrate;
    }
    case "tube": {
      if (erpSystem === "ch") {
        return validationTubeCH;
      }
      return validationTube;
    }
    case "profile": {
      return validationProfile;
    }
    default: {
      return validationSheet;
    }
  }
};
