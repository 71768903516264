import styled from "styled-components";

export const WidthMeasurement = styled.div`
  position: relative;
  left: 195px;
  bottom: 275px;
  height: 20px;
`;

export const ThicknessMeasurement = styled.div`
  position: relative;
  left: 90px;
  top: 5px;
  height: 20px;
`;

export const LengthMeasurement = styled.div`
  position: relative;
  left: 190px;
  bottom: 100px;
  height: 20px;
`;
