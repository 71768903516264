import styled from "styled-components";
import { colors } from "@eriksdigital/atomic-ui/styles";

export const Span = styled.span`
  color: ${(props) => (props.color ? props.color : colors.default.darkGray)};
  font-size: 14px;
`;

export const DeliveryDate = styled.span`
  color: ${({ theme }) => theme.colors.text.complementary};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft16};
`;
