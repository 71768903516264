import React, { useContext, useEffect, useCallback } from "react";
import {
  Icon,
  LanguageContext,
  Span,
  Link
} from "@eriksdigital/atomic-ui/components";
import {
  LoadingIcon,
  CartIcon
} from "@eriksdigital/atomic-ui/components/Icons";
import { colors } from "@eriksdigital/atomic-ui/styles";
import { IconWrapper, ItemCountCircle } from "./style";
import { constructShoppingUrl } from "../../utils/shopUtils";
import { AppContext } from "../../providers/AppProvider";
import { useRaffaella } from "@eriksdigital/raffaella";
import { languageToCode } from "utils/languageUtils";

const ShoppingCart = () => {
  const { erpSystem, language } = useContext<any>(LanguageContext);
  const [shoppingCart, makeResourceCallShopingCart] = useRaffaella(
    "shoppingCart"
  );
  const {
    updateState,
    state: { shoppingCartUrl }
  } = useContext<any>(AppContext);
  const [salesOrganization] = useRaffaella("salesOrganization");
  const x_totalOrderItemCount = shoppingCart?.data?.x_totalOrderItemCount ?? 0;

  const getShopingCart = useCallback(() => {
    makeResourceCallShopingCart &&
      makeResourceCallShopingCart({
        apiKey: "shoppingCart",
        reqData: {
          locale: languageToCode(language),
          erpSystem: erpSystem.toUpperCase(),
          ...(erpSystem === "ch" && {
            reconfigure: true
          }),
          targetSystem: "SEL_PLA"
        }
      });
  }, [makeResourceCallShopingCart, erpSystem, language]);

  useEffect(() => {
    getShopingCart();
  }, [getShopingCart]);

  useEffect(() => {
    if (salesOrganization.data && language && erpSystem) {
      const url = constructShoppingUrl(
        salesOrganization.data,
        language,
        erpSystem
      );
      updateState({
        shoppingCartUrl: url
      });
    }
  }, [salesOrganization, language, erpSystem, updateState]);

  return (
    <div id="navbar-cart-icon">
      <Link
        href={shoppingCartUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="navbar-go-to-cart"
      >
        <IconWrapper>
          <ItemCountCircle
            data-testid="item-count-circle"
            isLargeAmount={x_totalOrderItemCount > 99}
          >
            {shoppingCart.isLoading ? (
              <Icon as={LoadingIcon} size="sz8" color={colors.default.blueD} />
            ) : (
              x_totalOrderItemCount
            )}
          </ItemCountCircle>
          <Span id="cart-itemcount" style={{ display: "none" }}>
            {x_totalOrderItemCount}
          </Span>
          <Icon
            as={CartIcon}
            fill={colors.heading.primary}
            height={30}
            width={30}
            viewBox="0 0 35 35"
          />
        </IconWrapper>
      </Link>
    </div>
  );
};

export default ShoppingCart;
