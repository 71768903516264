import React from "react";

import { MenuButton, HamBar } from "./style";

interface Props {
  id: string;
  color?: string;
  open?: boolean;
  onClick(): void;
}

const Hamburger: React.FC<Props> = ({
  id,
  onClick,
  color = "#0163af",
  open = false,
  ...rest
}) => {
  return (
    <MenuButton id={id} data-testid="menu-button" onClick={onClick}>
      <HamBar color={color} {...rest} />
      <HamBar color={color} {...rest} />
      <HamBar color={color} {...rest} />
    </MenuButton>
  );
};

export default Hamburger;
