import React, { FC, useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { Vector3 } from "three";
import { FormattedMessage } from "react-intl";
import { TextContainer } from "./styles";
import { BoxShape } from "./BoxShape";

export type SheetPreviewProps = {
  color?: string;
  thickness?: number | undefined;
  length?: number | undefined;
  width?: number | undefined;
};

const INITIAL_RATIO_LENGTH = 1;
const INITIAL_RATIO_THICKNESS = 0.5;
const INITIAL_RATIO_WIDTH = 1;

/* Renders 3d preview of the Sheet based on entered values
 */
const SheetPreview3d: FC<SheetPreviewProps> = ({
  color,
  thickness,
  length,
  width
}: SheetPreviewProps) => {
  const [ratioWidth, setRatioWidth] = useState<number>(INITIAL_RATIO_WIDTH);

  const [ratioThickness, setRatioThickness] = useState<number>(
    INITIAL_RATIO_THICKNESS
  );

  const [ratioLength, setRatioLength] = useState<number>(INITIAL_RATIO_LENGTH);

  const getRatio = (
    length: number | undefined,
    width: number | undefined,
    thickness: number | undefined
  ): number => {
    return (
      1 /
      Math.max(length || -Infinity, width || -Infinity, thickness || -Infinity)
    );
  };

  useEffect(() => {
    const ratio = getRatio(length, width, thickness);
    if (ratio > 0) {
      if (width) {
        setRatioWidth(Number(width) * ratio);
      }
      if (thickness) {
        setRatioThickness(Number(thickness) * ratio);
      }
      if (length) {
        setRatioLength(Number(length) * ratio);
      }
    } else {
      setRatioWidth(INITIAL_RATIO_WIDTH);
      setRatioThickness(INITIAL_RATIO_THICKNESS);
      setRatioLength(INITIAL_RATIO_LENGTH);
    }
  }, [thickness, length, width, color]);

  return (
    <>
      <div data-testid="default-shape"></div>
      <Canvas orthographic camera={{ zoom: 100 }}>
        <ambientLight intensity={1.5} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <pointLight position={[-10, -10, -10]} />
        <directionalLight position={new Vector3(0, 3, 2)} />
        <directionalLight position={new Vector3(-4, 3, 2)} />
        <BoxShape
          width={ratioWidth}
          thickness={ratioThickness}
          length={ratioLength}
          color={color}
        />
      </Canvas>
      <TextContainer>
        <p data-testid="shape-length">
          <FormattedMessage
            id="step2.length"
            defaultMessage="Length(mm)"
            description="label of Length(mm) field"
          />
          : {length}
        </p>
        <p data-testid="shape-width">
          <FormattedMessage
            id="step2.width"
            defaultMessage="Width(mm)"
            description="label of product Width / Select field"
          />
          : {width}
        </p>
        <p data-testid="shape-thickness">
          <FormattedMessage
            id="step2.thickness"
            defaultMessage="Type / Select"
            description="label of Type / Select placeholder field"
          />
          : {thickness}
        </p>
      </TextContainer>
    </>
  );
};

export { SheetPreview3d };
