import React from "react";

export const TubeImage = ({ color = "white" }) => {
  return (
    <svg
      data-testid="tube-image"
      width="324"
      height="243"
      viewBox="0 0 324 243"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M282.954 77.9657L90.4768 226.371L60.6418 196.164L60.6379 196.16L29.7994 165.603L236.855 25.9145C258.514 27.2622 276.48 41.6406 279.81 60.3219L282.954 77.9657Z"
        stroke="#979797"
      />
      <path
        d="M23.5857 180.316C25.5614 169.218 30.6277 161.92 38.1458 157.872C45.6848 153.813 55.7878 152.968 67.9346 154.999C82.1632 160.045 91.6388 167.151 97.5616 175.537C103.484 183.923 105.88 193.625 105.884 203.911C104.651 214.549 99.1597 222.059 91.5005 226.644C83.8154 231.245 73.9091 232.922 63.8685 231.783C51.6242 230.394 40.5585 223.083 33.0448 213.31C25.529 203.534 21.6191 191.363 23.5857 180.316Z"
        fill={color}
        stroke="#979797"
      />
      <path
        d="M37.589 183.763C39.7381 178.184 43.6865 174.474 48.777 172.675C53.8839 170.87 60.192 170.971 67.0538 173.127C73.8667 175.267 80.1018 180.353 84.0864 186.326C88.0788 192.311 89.7453 199.08 87.6231 204.589C85.4716 210.175 81.354 214.307 76.0868 216.517C70.8155 218.729 64.3572 219.03 57.5205 216.881C50.7259 214.747 44.6502 209.263 40.8185 202.873C36.9798 196.471 35.4631 189.283 37.589 183.763Z"
        fill={color}
        stroke="#979797"
      />
    </svg>
  );
};

export default TubeImage;
