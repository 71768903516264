import styled from "styled-components";
import { H1 } from "@eriksdigital/atomic-ui/components";
import { breakpoints } from "@eriksdigital/atomic-ui/styles";
import { Theme } from "../../global.types";

export const MainTitle = styled(H1)`
  margin-top: ${({ theme }) => theme.spacing.sp24};
  margin-bottom: ${({ theme }) => theme.spacing.sp40};
  color: ${({ theme }) => theme.colors.default.black};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft20};

  ${breakpoints.tabletLandscape`
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.fontSize.ft40};
  `}
`;

export const Wrapper = styled.main`
  max-width: 82em;
  margin: 0px auto;
`;
