export const colorsMap = {
  "Dark grey": "#808080",
  "Dark green": "#7A7A3A",
  "Dark blue": "#00008b",
  "Gentian blue": "#004f7c",
  "Light grey": "#d3d3d3",
  "Red-brown": "#a52a2a",
  "Light green": "#90EE90",
  "Dark brown": "#654321",
  "Off-white": "#FAF9F6",
  "Crystal clear": "#F9F9F9",
  "Green Beige": "#C9BB88",
  "Light brown": "#b5651d",
  Bright: "#FDFEFF",
  Beige: "#F5F5DC",
  Green: "#008000",
  Red: "#ff0000",
  White: "#ffffff",
  Black: "#000000",
  Natural: "#d4ccb4",
  Blue: "#0000ff",
  Brown: "#842000",
  Grey: "#a9a9a9",
  Yellow: "#FFFF00",
  Transparent: "#ffff0000",
  "Transparent blue": "#ddddff",
  "Transparent white": "#00FFFFFF",
  Clear: "#ffffff00",
};

// TODO provide interface
export const translatedColorsMap: { [key: string]: any } = {
  en: {
    ...colorsMap,
  },
  nl: {
    Blauw: colorsMap["Blue"],
    Bruin: colorsMap["Brown"],
    Donkerblauw: colorsMap["Dark blue"],
    Donkergrijs: colorsMap["Dark grey"],
    Donkergroen: colorsMap["Dark green"],
    Grijs: colorsMap["Grey"],
    Groen: colorsMap["Green"],
    Lichtgrijs: colorsMap["Light grey"],
    Naturel: colorsMap["Natural"],
    Roodbruin: colorsMap["Red-brown"],
    Wit: colorsMap["White"],
    Zwart: colorsMap["Black"],
    Rood: colorsMap["Red"],
  },
  de: {
    Blau: colorsMap["Blue"],
    Braun: colorsMap["Brown"],
    "braun / Schwarz": colorsMap["Dark brown"],
    Dunkelblau: colorsMap["Dark blue"],
    Dunkelgrau: colorsMap["Dark grey"],
    Dunkelgrün: colorsMap["Dark green"],
    Enzianblau: colorsMap["Gentian blue"],
    Grau: colorsMap["Grey"],
    Grün: colorsMap["Green"],
    Lichtgrau: colorsMap["Light grey"],
    Natur: colorsMap["Natural"],
    Rotbraun: colorsMap["Red-brown"],
    Weiss: colorsMap["White"],
    Schwarz: colorsMap["Black"],
    Rot: colorsMap["Red"],
    "Gebrochen weiss": colorsMap["Off-white"],
    Gelb: colorsMap["Yellow"],
    Glasklar: colorsMap["Crystal clear"],
    "Grün/Beige": colorsMap["Green Beige"],
    Hell: colorsMap["Bright"],
    Hellbraun: colorsMap["Light brown"],
    Hellgrün: colorsMap["Light green"],
    Transparent: colorsMap["Transparent"],
    "Transparent blau": colorsMap["Transparent blue"],
    "Weiss transparent": colorsMap["Transparent white"],
  },
  fr: {
    Beige: colorsMap["Beige"],
    "Blanc cassé": colorsMap["Off-white"],
    "Brun / Noir": colorsMap["Dark brown"],
    Clair: colorsMap["Natural"],
    "Gris pâle": colorsMap["Light grey"],
    Jaune: colorsMap["Yellow"],
    Translucide: colorsMap["Crystal clear"],
    Transparent: colorsMap["Transparent"],
    "Transparent bleu": colorsMap["Transparent blue"],
    "Vert clair": colorsMap["Light green"],
    "Vert/beige": colorsMap["Green Beige"],
    Bleu: colorsMap["Blue"],
    Brun: colorsMap["Brown"],
    "Brun clair": colorsMap["Light brown"],
    "Enzian bleu": colorsMap["Blue"],
    "Bleu foncé": colorsMap["Dark blue"],
    "Gris foncé": colorsMap["Dark grey"],
    "Vert foncé": colorsMap["Dark green"],
    Gris: colorsMap["Grey"],
    "Gris / Blanc": colorsMap["Light grey"],
    Nature: colorsMap["Natural"],
    Vert: colorsMap["Green"],
    "Gris clair": colorsMap["Light grey"],
    Naturel: colorsMap["Natural"],
    "Rouge brun": colorsMap["Red-brown"],
    Blanc: colorsMap["White"],
    Noir: colorsMap["Black"],
    Rouge: colorsMap["Red"],
  },
};
