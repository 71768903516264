import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-final-form";
import {
  Box,
  LanguageContext,
  LoadingIndicator
} from "@eriksdigital/atomic-ui/components";
import { Preview } from "./Preview/Preview";
import {
  ActionButton,
  ActionButtonMobile,
  ActionContainer,
  FormContainer,
  ResetLink
} from "../styles";

import { BREAKPOINTS_LABELS, useMedia } from "../../../utils/useMedia";
import { mutators } from "./mutators";

import { transformDataFormToRequest } from "../utils";
import { selectFormFields, categoriesMap } from "../../../utils/utils";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router";
import { removeLastDirectoryPartOf } from "../../../utils/urlUtils.ts";
import { FormattedMessage, useIntl } from "react-intl";
import { Preview3D } from "./Preview3d";
import { shouldShow3dPreview } from "../../../utils/shouldShow3dPreview";

import useBasenamePath from "../../../utils/useBasenamePath";
import { GenericField } from "../../GenericField";
import { placeholderTextField, getValidationForForm } from "./validate";
import { useClearResource } from "@eriksdigital/raffaella";
import { ItemQuantityContext } from "providers/ItemQuantity";

export const CustomizerScreenForm = props => {
  const { onSubmit, attrs, getAttributes, modalError } = props;

  const { language, erpSystem } = useContext(LanguageContext);
  const isTablet = useMedia(BREAKPOINTS_LABELS.tabletLandscape);
  const hasUpdateForm = useRef(false);
  const { id, basicForm: category } = useParams();
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const basename = useBasenamePath();
  const { search } = useLocation();
  const clearItems = useClearResource("items");
  const clearAttributes = useClearResource("attributes");
  const { updateQuantity } = useContext(ItemQuantityContext);

  const catId = new URLSearchParams(search).get("catId");

  const [formFields, setFormFields] = useState(
    selectFormFields({ erpSystem, basicForm: category, catId, categoriesMap })
  );

  useEffect(() => {
    setFormFields(
      selectFormFields({ erpSystem, basicForm: category, catId, categoriesMap })
    );
  }, [erpSystem, category, catId]);

  const initialValues = {
    eriksName: null,
    isoName: null,
    materialClass: null,
    colour: null
  };
  let initValuesForm;

  const [attributesRemoved, setAttributesRemoved] = useState({});
  switch (category) {
    case "rod":
      initValuesForm = { ...initialValues, diameter: null };
      break;
    case "sheet":
      initValuesForm = { ...initialValues, thickness: null };
      break;
    case "grate":
      initValuesForm = { ...initialValues, thickness: null };
      break;
    case "tube":
      initValuesForm = {
        ...initialValues,
        diameter: null,
        internalDiameter: null
      };
      break;
    case "profile": {
      initValuesForm = { ...initialValues, thickness: null };
      break;
    }
    default:
      initValuesForm = { ...initialValues };
  }

  const validate = getValidationForForm(category, catId, erpSystem);

  const loadAttributes = () => {
    const values = window.Form.getState().values;

    const data = transformDataFormToRequest(values);

    getAttributes({
      type: category,
      lang: language,
      erpSystem: erpSystem.toUpperCase(),
      filter: {
        ...(catId ? { catId } : {}),
        ...data
      }
    });
  };

  const getAttributesByValue = (current, prev) => {
    if (current?.value !== prev?.value && hasUpdateForm.current) {
      if (window.Form.getState().dirty) {
        if (!current?.value && prev?.id) {
          setAttributesRemoved({ ...attributesRemoved, [prev.id]: true });
        } else {
          setAttributesRemoved({
            ...attributesRemoved,
            ...(prev?.id ? { [prev.id]: false } : {})
          });
        }
      }

      loadAttributes();
    }
  };

  const setAttrsToFormAsync = useCallback(
    async () =>
      attrs &&
      Object.keys(attrs).forEach(selectOption => {
        const item = attrs[selectOption];
        if (
          // in switzerland we don't want to preselect the value if there is only one option
          // but, if we are in reconfigure flow then we should, so here there will be a bug when we get wrong combination from backend
          ((erpSystem === "ch" && id !== undefined) || erpSystem !== "ch") &&
          item?.options?.length === 1 &&
          selectOption !== "width" &&
          selectOption !== "length" &&
          !attributesRemoved[selectOption] &&
          (window.Form.getState().dirty || id)
        ) {
          window.Form.mutators.setValue(
            selectOption,
            item.options
              ? {
                  id: selectOption,
                  label: item.options[0],
                  value: item.options[0]
                }
              : null
          );
        }
      }),
    [attrs, attributesRemoved, id, erpSystem]
  );

  const resetForm = e => {
    e.preventDefault();
    const values = window.Form.getState().values;
    if (values.width) {
      window.Form.mutators.setValue("width", "");
    }
    if (values.length) {
      window.Form.mutators.setValue("length", "");
    }
    for (const name in window.Form.getState().dirtyFields) {
      window.Form.resetFieldState(name);
    }
    window.Form.reset();
    setAttributesRemoved({});
    clearAttributes();
    clearItems();
    updateQuantity({ erpPartNumber: "", quantity: 1 });

    if (id) {
      history.push(removeLastDirectoryPartOf(location, params, basename));
    }
  };

  useEffect(() => {
    hasUpdateForm.current = false;
    const setSelectValuesToForm = async () => {
      if (!hasUpdateForm.current) {
        await setAttrsToFormAsync();
        hasUpdateForm.current = true;
      }
    };
    setSelectValuesToForm();
  }, [hasUpdateForm, setAttrsToFormAsync]);

  if (!!attrs === false) {
    return <LoadingIndicator />;
  }

  return (
    <Form
      mutators={mutators}
      initialValues={initValuesForm}
      validate={values => validate(values, intl, attrs)}
      onSubmit={onSubmit}
      render={({ form, handleSubmit, values }) => {
        window.Form = form;
        return (
          <Fragment>
            {isTablet && (
              <Box width="35%" marginRight="sp32">
                <Box display="flex" justifyContent="center">
                  {shouldShow3dPreview() ? (
                    <Preview3D type={category} values={values} />
                  ) : (
                    <Preview type={category} values={values} />
                  )}
                </Box>
              </Box>
            )}
            <Box width={!isTablet ? "100%" : "35%"} marginRight="sp32">
              <FormContainer>
                {!!formFields === true &&
                  formFields.map(field => {
                    return (
                      <GenericField
                        key={`key-${field.id}`}
                        id={field.id}
                        component={field.component}
                        attributeId={field.attributeId}
                        onChange={getAttributesByValue}
                        options={attrs[field.attributeId || field.id]?.options}
                        showModal={modalError}
                        placeholder={placeholderTextField(
                          field.attributeId || field.id,
                          attrs,
                          intl
                        )}
                        hideIf={
                          attrs[field.attributeId || field.id]?.options
                            ?.length === 0
                        }
                        {...field}
                      />
                    );
                  })}
              </FormContainer>
              {!isTablet && (
                <Box display="flex" alignItems="center" marginTop="sp24">
                  <ActionButtonMobile
                    id="showResults"
                    data-testid="showResults"
                    type="submit"
                    stretch="auto"
                    flex="1"
                    width="100%"
                    onClick={handleSubmit}
                  >
                    <FormattedMessage
                      id="step2.showResults"
                      defaultMessage="Show results"
                      description="Show results field"
                    />
                  </ActionButtonMobile>
                  <ResetLink
                    id="resetFields"
                    variant="secondary"
                    data-testid="resetFields"
                    stretch="auto"
                    onClick={resetForm}
                  >
                    <FormattedMessage
                      id="step2.clearAllFields"
                      defaultMessage="Clear all fields"
                      description="Show results field"
                    />
                  </ResetLink>
                </Box>
              )}
            </Box>
            {isTablet && (
              <Box width="30%">
                <ActionContainer>
                  <ActionButton
                    id="resetFields"
                    variant="secondary"
                    data-testid="resetFields"
                    stretch="auto"
                    onClick={resetForm}
                  >
                    <FormattedMessage
                      id="step2.clearAllFields"
                      defaultMessage="Clear all fields"
                      description="Show results field"
                    />
                  </ActionButton>
                  <ActionButton
                    id="showResults"
                    data-testid="showResults"
                    type="submit"
                    stretch="auto"
                    onClick={handleSubmit}
                  >
                    <FormattedMessage
                      id="step2.showResults"
                      defaultMessage="Show results"
                      description="Show results field"
                    />
                  </ActionButton>
                </ActionContainer>
              </Box>
            )}
          </Fragment>
        );
      }}
    />
  );
};

export default CustomizerScreenForm;
