import React, { Fragment } from "react";
import RodImage from "../../../../RodImage";
import { DiameterMeasurement, LengthMeasurement } from "./styles";

interface Props {
  color: string;
  thickness?: string;
  length?: string;
  width?: string;
  diameter?: string;
}

const RodePreview = (props: Props) => {
  const { color, diameter, length } = props;
  return (
    <Fragment>
      <RodImage color={color} />
      <DiameterMeasurement>{diameter}</DiameterMeasurement>
      <LengthMeasurement>{length}</LengthMeasurement>
    </Fragment>
  );
};

export default RodePreview;
