import styled from "styled-components";
import { H2, Button, Link } from "@eriksdigital/atomic-ui/components";
import { breakpoints } from "@eriksdigital/atomic-ui/styles";
import { Theme } from "../../global.types";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(H2)`
  color: ${({ theme }) => theme.colors.text.black};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft16};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};

  ${breakpoints.tabletLandscape`    
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.fontSize.ft24};
  font-weight: ${({ theme }: { theme: Theme }) =>
    theme.fonts.fontWeight.normal};
  `}
`;

export const ImageContainer = styled.div`
  position: absolute;
  margin-top: ${({ theme }) => theme.spacing.sp32};
`;
export const FormContainer = styled.form``;

export const ActionContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const Section = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sp16};
`;

export const ActionButton = styled(Button)`
  margin: 0 ${({ theme }) => theme.spacing.sp4};
`;

export const ActionButtonMobile = styled(ActionButton)`
  flex: 1 0 auto;
`;

export const ResetLink = styled(Link)`
  margin: 0 ${({ theme }) => theme.spacing.sp8};
  flex: 0 0 33%;
`;
