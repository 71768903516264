import styled from "styled-components";
import { Link } from "react-router-dom";

export const HeaderWrapper = styled.header`
  height: 64px;
  max-width: 82em;
  margin: 0px auto;
  padding: 0 ${({ theme }) => theme.spacing.sp16};
`;

export const HeaderInnerWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
`;

export const HeaderInner = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const LogoWrapper = styled(Link)`
  height: 64px;
  padding-top: 1em;
  display: flex;
  > div {
    display: flex;
  }
  svg {
    width: 6rem;
  }
`;

export const MenuPanelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LanguagePanel = styled.div<{ menuOpen?: boolean }>`
  position: relative;
  width: 200px;
  top: 10px;
  z-index: 1;
  height: auto;
  transform: scaleY(${(props) => (props.menuOpen ? "1" : "0")});
  overflow: hidden;
  background-color: white;
  padding: 20px;
  border: ${(props) => (props.menuOpen ? "1px solid #dedede" : "0")};
  opacity: ${(props) => (props.menuOpen ? "1" : "0")};
  transition: transform 0.1s, opacity 0.3s;
`;

export const IconWrapper = styled.span`
  position: relative;
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing.sp8};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px 0px;
  max-width: 300px;
  width: 100%;
  div + label {
    margin-top: 10px;
  }
`;

export const StyledSelect = styled.select`
  border: 1px solid #d2d0d0;
  padding: 0.5rem;
  padding-right: 25px;
  min-width: 100%;
  border-radius: 2px;
  background: transparent;
  background-image: none;
  appearance: none;
  &:focus {
    border: 1px solid rgba(0, 92, 169, 1);
  }
`;
