import { languageToCode } from "./languageUtils";

interface SalesOrganization {
  shop_url: string;
  store_id: number;
  country: string;
}

export const constructShoppingUrl = (
  data: any,
  language: "nl" | "en" | "fr" | "de",
  erpSystem: string
) => {
  if (data === null || data.getSalesOrganizations === null) return "/";
  const salesOrganization: SalesOrganization = data.find(
    (org: SalesOrganization) => org.country === erpSystem.toUpperCase()
  );
  if (!salesOrganization) return "/";
  return `${
    salesOrganization.shop_url
  }ShoppingCartDisplay?catalogId=1000&langId=${languageToCode(
    language
  )}&storeId=${salesOrganization.store_id}`;
};
