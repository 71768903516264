import React, { useContext, useEffect, useState } from "react";
import { MainTitle, Wrapper } from "./styles";
import { Route, Switch, Redirect } from "react-router-dom";
import useBasenamePath from "../../utils/useBasenamePath";
import IntroScreen from "../../components/IntroScreen";
import LoginPopup from "../../components/LoginPopup/LoginPopup";
import { LanguageContext } from "@eriksdigital/atomic-ui/components";
import { useRaffaella } from "@eriksdigital/raffaella";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import CategoriesScreen from "../../components/CategoriesScreen";
import CustomizerScreen from "../../components/CustomizerScreen";

const AppContainer: React.FC = () => {
  const basenamePath = useBasenamePath();
  const [user] = useRaffaella("user");
  const { erpSystem } = useContext<any>(LanguageContext);

  const [showLoginPopup, setShowLoginPopup] = useState<boolean | null>(null);
  const intl = useIntl();

  useEffect(() => {
    if (
      !!user.data &&
      typeof user.data.isGuestUser !== "undefined" &&
      typeof user.data.loggedIn !== "undefined"
    ) {
      setShowLoginPopup(() => {
        if (erpSystem === "ch") {
          return !user.data.loggedIn;
        }
        return user.data.isGuestUser;
      });
    }
  }, [user.data, erpSystem]);

  const handleLogin = () => {
    window.location.href = user.data.loginUrl;
  };

  const handleWithoutLogin = () => {
    setShowLoginPopup(false);
  };

  const getTitleByCountry = () => {
    switch (erpSystem) {
      case "ch": {
        //Specific title for POC EGS-3732
        return intl.formatMessage({
          id: "title.ch",
          defaultMessage: "Plastics Configurator",
          description: "Label of Title of the page"
        });
      }
      default: {
        return intl.formatMessage({
          id: "title",
          defaultMessage: "Plastics Configurator",
          description: "Label of Title of the page"
        });
      }
    }
  };

  return (
    <Wrapper>
      <Helmet>
        <title>{getTitleByCountry()}</title>
      </Helmet>
      <MainTitle>{getTitleByCountry()}</MainTitle>
      <Switch>
        <Route exact path={basenamePath} component={IntroScreen} />
        <Route
          exact
          path={`${basenamePath}/categories`}
          component={CategoriesScreen}
        />
        <Route
          exact
          path={`${basenamePath}/categories/:basicForm`}
          component={CustomizerScreen}
        />
        <Route
          exact
          path={`${basenamePath}/categories/:basicForm/:id`}
          component={CustomizerScreen}
        />
        <Redirect from="*" to={basenamePath} />
      </Switch>
      {showLoginPopup === true && (
        <LoginPopup onLogin={handleLogin} onWithoutLogin={handleWithoutLogin} />
      )}
    </Wrapper>
  );
};

export default AppContainer;
