import React, { useContext } from "react";
import { ImageContainer } from "../../styles";
import { translatedColorsMap } from "../../ColorPalette/colorsMap";
import { LanguageContext } from "@eriksdigital/atomic-ui/components";
import TubePreview from "./TubePreview";
import SheetPreview from "./SheetPreview";
import RodePreview from "./RodePreview";

interface PreviewProps {
  type: string;
  values: any;
}

export const Preview = ({ type, values }: PreviewProps) => {
  const { language } = useContext<any>(LanguageContext);

  const color = translatedColorsMap[language][values.colour?.value] || "white";
  const diameter = values?.diameter ? `${values.diameter?.value} mm` : "";
  const internalDiameter = values?.internalDiameter
    ? `${values?.internalDiameter?.value} mm`
    : "";
  const length = values?.length ? `${values?.length} mm` : "";
  const thickness = values?.thickness ? `${values?.thickness?.value} mm` : "";
  const width = values?.width ? `${values?.width} mm` : "";

  return (
    <ImageContainer data-testid="image">
      {
        {
          rod: (
            <div data-testid="rod-preview">
              <RodePreview color={color} diameter={diameter} length={length} />
            </div>
          ),
          sheet: (
            <div data-testid="sheet-preview">
              <SheetPreview
                color={color}
                thickness={thickness}
                length={length}
                width={width}
              />
            </div>
          ),
          tube: (
            <div data-testid="tube-preview">
              <TubePreview
                color={color}
                diameter={diameter}
                length={length}
                internalDiameter={internalDiameter}
              />
            </div>
          ),
          grate: (
            <div data-testid="grate-preview">
              <SheetPreview
                color={color}
                thickness={thickness}
                length={length}
                width={width}
              />
            </div>
          ),
          profile: (
            <div data-testid="profile-preview">
              <SheetPreview
                color={color}
                thickness={thickness}
                length={length}
                width={width}
              />
            </div>
          )
        }[type]
      }
    </ImageContainer>
  );
};
