import styled from "styled-components";
import { Icon, H2 } from "@eriksdigital/atomic-ui/components";
import { breakpoints } from "@eriksdigital/atomic-ui/styles";
import { Theme } from "../../global.types";

export const UList = styled.ul`
  margin-top: 10px;
`;

export const ListI = styled.li`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled(Icon)`
  fill: ${({ theme }) => theme.colors.default.green};
  margin-right: 8px;
`;

export const Title = styled(H2)`
  line-height: ${({ theme }) => theme.fonts.lineHeight.text};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft20};
  ${breakpoints.tabletLandscape`
    line-height: ${({ theme }: { theme: Theme }) =>
      theme.fonts.lineHeight.heading};
    font-size:${({ theme }: { theme: Theme }) => theme.fonts.fontSize.ft32};
  `}
`;
