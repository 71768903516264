import React from "react";
import { Divider } from "@eriksdigital/atomic-ui/components";
import { defaultTheme } from "@eriksdigital/atomic-ui/styles";
import { ResourcesProvider } from "@eriksdigital/raffaella";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Header";
import { NotificationProvider } from "./providers/NotificationProvider";
import { NotificationCenter } from "./components/NotificationCenter";
import { ThemeProvider } from "styled-components";
import { AppProvider } from "./providers/AppProvider";
import AppContainer from "./containers/AppContainer/AppContainer";
import { TranslationProvider } from "./providers/TranslationProvider";
import { config } from "./resourceConfig";
import { ItemQuantityProvider } from "providers/ItemQuantity";

const apiPath = () => {
  const {
    location: { origin }
  } = window;
  return `${origin}/plastics-customizer/api`;
};

const App = () => {
  return (
    //@ts-ignore
    <Router>
      <AppProvider>
        <ResourcesProvider config={config} apiPath={apiPath()}>
          <NotificationProvider>
            <ItemQuantityProvider>
              <ThemeProvider theme={defaultTheme}>
                <TranslationProvider>
                  <Header />
                  <Divider />
                  <AppContainer />
                  <NotificationCenter />
                </TranslationProvider>
              </ThemeProvider>
            </ItemQuantityProvider>
          </NotificationProvider>
        </ResourcesProvider>
      </AppProvider>
    </Router>
  );
};

export default App;
