import styled from "styled-components";
import { Button, H2, P } from "@eriksdigital/atomic-ui/components";
import { breakpoints } from "@eriksdigital/atomic-ui/styles";

import { Theme } from "../global.types";

export const Title = styled(H2)`
  color: ${({ theme }) => theme.colors.text.black};
  line-height: ${({ theme }) => theme.fonts.lineHeight.text};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft16};
  ${breakpoints.tabletLandscape`
    line-height: ${({ theme }: { theme: Theme }) =>
      theme.fonts.lineHeight.heading};
    font-size:  ${({ theme }: { theme: Theme }) => theme.fonts.fontSize.ft32};  
  `}
`;

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.sp16};
  ${breakpoints.tabletLandscape`
    padding:  ${({ theme }: { theme: Theme }) => theme.spacing.sp32};  
  `}
`;

export const ContainerWrapper = styled.div`
  border: none;
  margin-bottom: ${({ theme }) => theme.spacing.sp16};

  ${breakpoints.tabletLandscape`
    border: 1px solid  ${({ theme }: { theme: Theme }) =>
      theme.colors.border.gray};
  `}
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;

  ${breakpoints.tabletLandscape`
    margin-top:  ${({ theme }: { theme: Theme }) => theme.spacing.sp64};
    margin-bottom:  ${({ theme }: { theme: Theme }) => theme.spacing.sp64};
  `}
`;

export const StyledButton = styled(Button)`
  padding: ${({ theme }: { theme: Theme }) => theme.spacing.sp16};
  border: 1px solid #c8ccd6;
  border-radius: 5px;
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing.sp24};
  ${({ leftMargin }) => leftMargin};
  &:hover {
    text-decoration: none;
    border: 1px solid #005ca9;
    background: #f3fbfe;
  }
`;
export const StyledButtonText = styled(P)`
  font-weight: bold;
  margin-left: ${({ theme }: { theme: Theme }) => theme.spacing.sp16};
`;
