import React from "react";
import {
  Box,
  ModalLegacy,
  Divider,
  P,
  Button
} from "@eriksdigital/atomic-ui/components";

import { Description, TitleWrapper } from "./styles";
import { FormattedMessage } from "react-intl";

interface Props {
  onLogin(): void;
  onWithoutLogin(): void;
}

export const LoginPopup: React.FC<Props> = props => {
  const { onLogin, onWithoutLogin } = props;

  return (
    <ModalLegacy onClose={onWithoutLogin}>
      <Box marginBottom="sp16">
        <TitleWrapper>
          <FormattedMessage
            id="login.title"
            defaultMessage="You are not logged in"
            description="Not logged in message"
          />
        </TitleWrapper>
      </Box>
      <Divider />
      <Description>
        <P>
          <FormattedMessage
            id="login.description"
            defaultMessage="Please login to see your customer specific pricing and adding customized items to your cart. If you proceed without logging on the ERIKS webshop, the Plastics Configurator will display products without a price."
            description="login description"
          />
        </P>
      </Description>
      <Box display="inlineBlock" marginRight="sp16" marginBottom="sp12">
        <Button
          id="login-button"
          data-testid="loginButton"
          stretch="auto"
          variant="primary"
          onClick={onLogin}
        >
          <FormattedMessage
            id="login.loginButton"
            defaultMessage="Login for customer specific pricing"
            description="Logged Button Label"
          />
        </Button>
      </Box>
      <Button
        id="without-login-button"
        data-testid="withoutLoginButton"
        stretch="auto"
        variant="secondary"
        onClick={onWithoutLogin}
        marginBottom="sp12"
      >
        <FormattedMessage
          id="login.withoutLoginButton"
          defaultMessage="I will proceed without login"
          description="Without login button label"
        />
      </Button>
    </ModalLegacy>
  );
};

export default LoginPopup;
