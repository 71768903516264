import React from "react";

export const SheetImage = ({ color }: { color: string }) => {
  return (
    <svg
      data-testid="sheet-image"
      xmlns="http://www.w3.org/2000/svg"
      width="240"
      height="240"
      viewBox="0 0 209 208"
    >
      <g fill={color} fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#979797" transform="translate(-229 -281)">
          <g transform="translate(226 265)">
            <path
              d="M56.8322853 31.9721639L169.932737 21 169.932737 193 44.9327366 193z"
              opacity="0.749"
              transform="rotate(40 107.433 107)"
            />
            <path
              d="M151.925623 62.5253675L158.881217 70.8147221 158.424471 241.5145 151.925623 234.525368z"
              transform="rotate(40 155.403 152.02)"
            />
            <path
              d="M-13 174.613282L112 174.613282 117.627806 181.32024 -7.54039683 181.119784z"
              transform="rotate(40 52.314 177.967)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SheetImage;
