import React, { ChangeEvent, Fragment } from "react";
import { Box, Button, ModalLegacy } from "@eriksdigital/atomic-ui/components";

interface Props {
  text: string;
  buttonText: string;
  show: boolean;
  onClick(e: ChangeEvent): void;
}

export const ModalError: React.FC<Props> = ({
  text,
  buttonText,
  show,
  onClick,
}) => (
  <Fragment>
    {show && (
      <ModalLegacy showCloseIcon={false}>
        <Box
          data-testid={"modalErrorMessage"}
          display="flex"
          direction="column"
          alignItems="center"
        >
          {text}
          <Box marginTop="sp16">
            <Button
              id="primary_auto-width"
              onClick={onClick}
              text={buttonText}
              stretch="auto"
            />
          </Box>
        </Box>
      </ModalLegacy>
    )}
  </Fragment>
);
