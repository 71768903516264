import React, { FC, useRef } from "react";

export type BoxShapeProps = {
  color?: string;
  thickness?: number;
  length?: number;
  width?: number;
};

const BoxShape: FC<BoxShapeProps> = ({
  color,
  thickness,
  length,
  width,
}: BoxShapeProps) => {
  const ref = useRef();
  return (
    <mesh ref={ref} position={[0, 0, 0]} rotation={[0.6, -0.3, 0.6]}>
      <boxGeometry args={[width, length, thickness]} />
      <meshPhongMaterial color={color} opacity={1} transparent={false} />
    </mesh>
  );
};

export { BoxShape };
