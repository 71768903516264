import React, { Fragment } from "react";
import SheetImage from "../../../../SheetImage";
import {
  LengthMeasurement,
  ThicknessMeasurement,
  WidthMeasurement
} from "./styles";

interface Props {
  color: string;
  thickness?: string;
  length?: string;
  width?: string;
}

const SheetPreview = (props: Props) => {
  const { color, thickness, length, width } = props;
  return (
    <Fragment>
      <SheetImage color={color} />
      <ThicknessMeasurement>{thickness}</ThicknessMeasurement>
      <LengthMeasurement>{length}</LengthMeasurement>
      <WidthMeasurement>{width}</WidthMeasurement>
    </Fragment>
  );
};

export default SheetPreview;
