import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Placeholder,
  LanguageContext
} from "@eriksdigital/atomic-ui/components";
import { GTMContext } from "@eriksdigital/gs-ga4";
import Item from "../Item";
import { Wrapper, LoadingWrapper } from "./styles";
import { useRaffaella } from "@eriksdigital/raffaella";
import { getCharacteristics } from "utils/getCharacteristics";
import { ItemQuantityContext } from "providers/ItemQuantity";
import { Item as SingleItem } from "../../types";

interface Props {
  type?: string;
  loading?: boolean;
  items?: SingleItem[];
}

export const Results = (props: Props) => {
  const { items, type, loading } = props;
  const { sendViewItemEvent } = useContext<any>(GTMContext);
  const [user] = useRaffaella("user");
  const [price, makeResourceCallPrice] = useRaffaella("price");
  const [stock, makeResourceCallStock] = useRaffaella("stockLevels");
  const { erpSystem, language } = useContext<any>(LanguageContext);
  const {
    state: { erpPartNumber: contextErpNumber, quantity }
  } = useContext<any>(ItemQuantityContext);
  const [newItems, setNewItems] = useState<any>([]);
  const [loadingNewItems, setLoadingNewItems] = useState<boolean>(true);

  useEffect(() => {
    if (!loading && items && items?.length > 0) {
      const itemsArray = items.map((item: SingleItem) => {
        const { erpPartNumber, width, length, configuration } = item;

        return {
          position: 1,
          materialNumber: erpPartNumber,
          configurations: configuration,
          quantity: erpPartNumber === contextErpNumber ? quantity : "1",
          characteristics: type && getCharacteristics({ type, width, length })
        };
      });
      const erpNumbers = items.map((item: any) => {
        return item.erpPartNumber;
      });

      makeResourceCallPrice &&
        makeResourceCallPrice({
          apiKey: "price",
          reqBody: {
            currency: erpSystem.toUpperCase() === "CH" ? "CHF" : "EUR",
            country: erpSystem.toUpperCase(),
            customerNumber: user?.data?.customerNumber,
            language,
            configuredItems: [...itemsArray]
          },
          ...(erpSystem === "ch" && {
            reqData: {
              reconfigure: true
            }
          })
        });

      makeResourceCallStock &&
        makeResourceCallStock({
          apiKey: "stockLevels",
          reqData: {
            erpSystem: erpSystem.toUpperCase(),
            itemsNumbers: erpNumbers.toString(),
            ...(erpSystem === "ch" && {
              reconfigure: true
            })
          }
        });
    }
  }, [
    items,
    erpSystem,
    language,
    makeResourceCallPrice,
    makeResourceCallStock,
    type,
    user,
    quantity,
    contextErpNumber,
    loading
  ]);

  useEffect(() => {
    const data =
      (!!price.data || !!stock.data) && items && items?.length > 0
        ? items.map(item => {
            const itemPrice = price.data
              ? price.data.customizedItems.filter(
                  (i: { materialNumber: string }) => {
                    return i.materialNumber === item.erpPartNumber;
                  }
                )
              : [];

            const itemStock = stock.data
              ? stock.data.items.filter((i: { number: string }) => {
                  return i.number === item.erpPartNumber;
                })
              : [];

            return {
              ...item,
              ...(itemPrice !== null && {
                ...itemPrice[0]
              }),
              ...(itemStock !== null && {
                itemStock: itemStock[0]
              })
            };
          })
        : [];

    if (data) {
      setLoadingNewItems(false);
      setNewItems(data);
    }
  }, [items, price.data, stock.data]);

  const sendItemEvent = useCallback(() => {
    if (newItems && newItems.length > 0 && !loadingNewItems) {
      const viewItemEventPayload = newItems?.map(
        (item: SingleItem, index: number) => {
          return {
            itemName: item.englishDescription,
            itemId: item.partNumber,
            price: item.priceData?.netPrice,
            itemBrand: item?.brand ? item.brand : "",
            itemStockStatus: item.itemStock?.available,
            index: index + 1,
            materialNumber: item.erpPartNumber
          };
        }
      );

      sendViewItemEvent && sendViewItemEvent(viewItemEventPayload);
    }
  }, [newItems, sendViewItemEvent, loadingNewItems]);

  useEffect(() => {
    if (!loadingNewItems) {
      sendItemEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendItemEvent]);

  return (
    <Wrapper>
      {loadingNewItems && (
        <LoadingWrapper>
          <Placeholder type="product" />
          <Placeholder type="product" />
        </LoadingWrapper>
      )}
      {!loadingNewItems && newItems.length > 0 && (
        <div data-testid="results">
          {newItems &&
            newItems.map((item: SingleItem, i: number) => {
              return <Item key={i} type={type} data={item} index={i} />;
            })}
        </div>
      )}
    </Wrapper>
  );
};

export default Results;
