export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "test";
export const NEW_DOMAIN = process.env.REACT_APP_NEW_DOMAIN || "no";

// Shape characteristic values
export const CON_PL_ROD_LENGTH_MM = "CON_PL_ROD_LENGTH_MM";
export const CON_PL_SH_LENGTH_MM = "CON_PL_SH_LENGTH_MM";
export const CON_PL_SH_WIDTH_MM = "CON_PL_SH_WIDTH_MM";
export const CON_PL_TUBE_LENGTH_MM = "CON_PL_TUBE_LENGTH_MM";
export const CON_PL_GR_LENGTH_MM = "CON_PL_GR_LENGTH_MM";
export const CON_PL_GR_WIDTH_MM = "CON_PL_GR_WIDTH_MM";
export const CON_PL_PROF_LENGTH_MM = "CON_PL_PROF_LENGTH_MM";
