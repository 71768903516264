import styled from "styled-components";
import { P, Box } from "@eriksdigital/atomic-ui/components";
import {
  colors,
  spacing,
  fonts,
  breakpoints,
} from "@eriksdigital/atomic-ui/styles";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: ${spacing.sp16};

  ${breakpoints.tabletLandscape`
    flex-direction: row;
    padding: ${spacing.sp32};
  `}
`;

export const TextWrapper = styled.div`
  margin-left: ${spacing.sp32};
`;

export const Title = styled.div`
  color: ${colors.text.secondary};
  font-size: ${fonts.fontSize.ft20};

  ${breakpoints.tabletLandscape`
    font-size: ${fonts.fontSize.ft24};  
  `}
`;
export const EriksNumber = styled(P)`
  font-size: ${fonts.fontSize.ft16};

  ${breakpoints.tabletLandscape`
    font-size: ${fonts.fontSize.ft20};
  `}
`;

export const DeliveryDate = styled.span`
  color: ${colors.text.complementary};
  font-size: ${fonts.fontSize.ft16};
  margin-bottom: ${spacing.sp8};

  ${breakpoints.tabletLandscape`
    margin-bottom: 0;
  `}
`;

export const MainBlock = styled(Box)`
  width: 100%;
  ${breakpoints.tabletLandscape`
    width: 40%;
  `}
`;

export const PriceBlock = styled(Box)`
  width: 100%;
  ${breakpoints.tabletLandscape`
    width: auto;  
  `}
`;

export const NoPriceMessage = styled(Box)`
  width: 100%;
  ${breakpoints.tabletLandscape`
    width: 60%;
  `}
`;
