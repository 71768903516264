import React, { createContext, useCallback, useReducer } from "react";

type Action = {
  type: string;
  payload: object;
};

interface State {
  shoppingCartUrl: string;
  categoriesConfigState: string;
}

interface AppContextInterface {
  state: State;
  updateState(state: Partial<State>): void;
}

const defaultState: State = {
  shoppingCartUrl: "",
  categoriesConfigState: "",
};

export const AppContext = createContext<AppContextInterface>({
  state: defaultState,
  updateState(): void {},
});

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "UPDATE_STATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const dispatchUpdateState = (state: State) => {
    dispatch({
      type: "UPDATE_STATE",
      payload: state,
    });
  };

  const updateState = useCallback((state: State) => {
    dispatchUpdateState(state);
  }, []);

  const context = {
    state,
    updateState,
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
